.cn11_img {
  max-width: 448px;
  height: 264px; }
.person_link {
  cursor: pointer;
  &:hover {
    text-decoration: none; } }
/* fix styles */
.icon {
  display: block; }
* {
  outline: none !important; }
body {
  padding: 0 !important;
  margin: 0; }
.hiddenInput {
  display: none !important; }
/* fix file form */
.icon-s-file {
  cursor: pointer; }
.link_file {
  cursor: pointer; }
.file_group input {
  display: none !important; }
/* fix slider */
.owl-nav {
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35%; }
.owl-prev {
  float: left;
  margin-left: -50px; }
.owl-next {
  float: right;
  margin-right: -50px; }
.icon-s-link_right {
  background-image: url(sprites/sprite.png);
  background-position: 0px 0px;
  width: 26px;
  height: 63px;
  transform: scale(-1,1); }
/* fix check */
.check_group {
  margin-top: 0px;
  width: 100%;
  margin-left: 85px;
  float: none;
  padding-top: 10px;
  .check_block {
    display: block;
    width: 21px;
    height: 20px;
    float: left;
    margin-right: 15px;
    margin-top: -2px;
    cursor: pointer;
    background-size: 21px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../images/check.png);
    &.active {
      background-image: url(../images/check_ok.png); } }
  p {
    color: #fff;
    a {
      color: #fff !important;
      font-family: "Rubik Light";
      font-size: 12px;
      text-align: left;
      display: block;

      line-height: 20px;
      float: left;
      &:hover {
        text-decoration: none;
        color: #fff; } } } }
/* form block */
.right_block {
  float: left;
  width: 35%;
  margin-left: -40px;
  margin-top: -45px;
  .r_text {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    padding-left: 80px;
    margin-top: 20px;
    width: 100%; } }
.form_block {
  border: 4px solid #e4428c;
  width: 70%;
  float: left;
  margin: -105px 0 0 -65px;
  padding-top: 85px;
  padding-bottom: 85px;
  position: relative;
  .form_title {
    font-family: "Gilroy ExtraBold";
    font-size: 60px;
    line-height: 55px;
    color: #fff;
    text-transform: uppercase;
    text-align: center; }
  .form_subtitle {
    font-family: "Gilroy Light";
    line-height: 36px;
    font-size: 26px;
    color: #fff;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  form {
    width: 65%;
    margin: 0 auto;
    margin-top: 50px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px; }
      #name_cnt8, #phone_cnt8 {
        width: 49%; }
      #phone_cnt8 {
        float: right; }
      textarea {
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin-top: 15px; } } } }
/* bg */
#content1,
#content2,
#content3,
#content4,
#content5,
#content6,
#content7,
#content8,
#content9,
#content10,
#content11,
#content12,
#content13,
#content14,
#content15,
#content16,
#content17,
#content18 {
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover; }
#content1 {
  background-image: url(../images/bg1.png);
  max-height: 1007px;
  position: relative;
  z-index: 2; }
#content2 {
  background-image: url(../images/bg2.jpg);
  max-height: 734px; }
#content3 {
  background-image: url(../images/bg3.jpg);
  max-height: 780px; }
#content4 {
  background-image: url(../images/bg4.jpg);
  max-height: 842px; }
#content5 {
  background-image: url(../images/bg5.jpg);
  max-height: 1039px; }
#content6 {
  background-image: url(../images/bg6.jpg);
  max-height: 721px; }
#content7 {
  background-image: url(../images/bg7.jpg);
  max-height: 1834px; }
#content8 {
  background-image: url(../images/bg8.jpg);
  max-height: 740px; }
#content9 {
  background-image: url(../images/bg9.jpg);
  max-height: 869px; }
#content10 {
  background-image: url(../images/bg10.jpg);
  max-height: 721px; }
#content11 {
  background-image: url(../images/bg11.jpg);
  max-height: 897px; }
#content12 {
  background-image: url(../images/bg12.jpg);
  max-height: 560px; }
#content13 {
  background-image: url(../images/bg13.jpg);
  max-height: 1455px; }
#content14 {
  background-image: url(../images/bg14.jpg);
  max-height: 997px; }
#content15 {
  background-image: url(../images/bg15.jpg);
  max-height: 741px; }
#content16 {
  background-image: url(../images/bg16.jpg);
  max-height: 580px; }
#content17 {
  background-image: url(../images/bg17.jpg);
  max-height: 608px; }
#content18 {
  background-image: url(../images/bg18.jpg);
  max-height: 740px; }
footer {
  background-image: url(../images/footer_bg.jpg);
  max-height: 205px; }
/* all fix */
.h_metro {
  a:hover {
    cursor: pointer; } }
.txt_block.border_block {
  color: #fff;
  font-size: 12px;
  position: absolute;
  font-family: "Rubik Light";
  line-height: 18px; }
.btn_style {
  color: #fff;
  background-color: #5fa9b4;
  height: 45px;
  width: auto;
  cursor: pointer;
  vertical-align: middle;
  font-family: Gilroy ExtraBold;
  text-align: center;
  padding: 11px 30px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: .5s;
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: #4a828a; } }
.btn_style2 {
  color: #fff;
  background-color: #e4428c;
  height: 45px;
  width: auto;
  vertical-align: middle;
  font-family: Gilroy ExtraBold;
  text-align: center;
  padding: 11px 30px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: .5s;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: #b2346e; } }
hr.red {
  margin: 0;
  border-top: none;
  border-bottom: 5px solid #e4428c;
  width: 100px; }
.redb_block {
  border: 5px solid #e4428c; }
.border_block {
  border: 4px solid #e4428c; }
.subtitle {
  p {
    font-size: 26px;
    line-height: 34px;
    font-family: Gilroy Light; } }
.title_block {
  font-family: Gilroy ExtraBold;
  line-height: 50px;
  font-size: 50px;
  text-transform: uppercase; }
.check_style {
  list-style-image: url(../images/list_ok.png);
  padding-left: 20px;
  font-family: "Rubik Light";
  line-height: 24px;
  margin-top: 5px;
  li {
    margin-bottom: 2px; } }
/* and all fix */
body {
  font-family: Rubik Light;
  font-size: 16px;
  line-height: 24px; }

/* main style */
header {
  padding: 15px;
  background-color: #fff;
  padding-bottom: 40px;
  height: 80px;
  .logo {
    float: left; }
  .h_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 34px;
    float: left;
    line-height: 20px;
    padding-top: 5px; }
  .h_metro {
    float: left;
    padding-left: 276px;
    a {
      float: left;
      clear: left;
      color: #068091;
      width: 220px;
      &:hover {
        text-decoration: none;
        color: #bd0559;
        p {
          border-bottom: 2px dotted #bd0559; } }

      p {
        margin-bottom: 0;
        font-family: Rubik Light;
        display: block;
        float: left;
        border-bottom: 2px dotted #068091; } }
    i.icon {
      float: left;
      margin-right: 10px;
      margin-top: 5px; } }
  .h_time {
    float: left;
    p {
      margin-bottom: 0; } }
  .h_callback {
    float: right;
    width: auto;
    a.callbtn {
      float: right;
      margin-top: 1px; }
    a.number {
      text-decoration: none;
      color: #000;
      font-family: Rubik Medium;
      font-size: 28px;
      line-height: 20px;
      cursor: default;
      margin-right: 15px;
      padding-top: 15px;
      display: block;
      float: left; } } }

@media (min-width: 1600px) {
  #video {
    min-height: 1100px; }
  #content1 {
    margin-top: -465px !important; } }
#video {
  position: relative;
  height: 100%;

  .overlay {
    height: 100%;
    min-height: 1000px;
    position: absolute;
    width: 100%;
    background-color: rgba(22, 62, 68, .9);
    z-index: 1; }
  #bg_block {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #aaa;
    .overlay {
      background-color: rgba(22, 62, 68, .9);
      width: 100%;
      height: 100%; } }
  .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 88px;
    max-width: 87%;
    position: relative;
    z-index: 9;
    .video_title {
      color: #fff;
      font-size: 70px;
      line-height: 65px;
      font-family: "Gilroy ExtraBold";
      text-align: center;
      text-transform: uppercase;
      padding: 0 10%;
      padding-top: 60px;
      padding-bottom: 23px; }
    .subtitle_video {
      width: 100%;
      p {
        color: #fff;
        text-align: center;
        display: block; } }
    .cnt1_subblock {
      margin-top: 60px;
      p {
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-family: "Rubik Light";
        width: 100%;
        margin-bottom: 0; } }
    .cnt1_btnblock {
      a.btn_style2 {
        width: 318px;
        margin: 0 auto;
        display: block;
        padding: 20px 20px;
        height: 55px;
        line-height: 12px;
        margin-top: 15px; } }
    .cnt1_write {
      width: 80%;
      margin: 0 auto;
      margin-top: 156px;
      padding-left: 10px;
      margin-bottom: 50px;
      p {
        float: left;
        color: #fff;
        font-family: "Rubik Light";
        font-size: 20px;
        padding-right: 38px; }
      img {
        margin-top: -10px;
        display: inline-block; }
      img.block2 {
        margin-left: 45px;
        margin-right: 25px; } } } }
#content1 {
  margin-top: -310px;
  padding-top: 462px;
  .block_left {
    padding-right: 73px;
    padding-left: 0;
    .title_block.title_cnt1 {
      color: #76c6d2;
      text-align: right; }
    .video_block {
      background-color: #aaa;
      width: 99%;
      margin-top: 85px;
      height: 384px; } }
  .block_right {
    margin-left: -25px;
    padding-top: 2px;
    padding-right: 0;
    color: #fff;
    hr.red {
      margin-top: 35px;
      margin-bottom: 40px; }
    .subtitle.subtitle_cnt1 {
      width: 90%; } } }
#content2 {
  padding-top: 345px;
  padding-bottom: 33px;
  .block_left {
    padding-left: 0;
    .subtitle.subtitle_cnt2 {
      width: 80%;
      text-align: left;
      color: #188e9e; }
    hr.red {
      margin-top: 35px;
      float: left; }
    .cnt2_text {
      float: left;
      width: 81%;
      padding-left: 65px;
      padding-top: 28px;
      p {
        padding-bottom: 0px; } } } }
#content3 {
  padding-top: 225px;
  .block_left {
    padding-right: 35px;
    .subtitle.subtitle_cnt3 {
      p {
        text-align: right;
        color: #188e9e; } }
    hr.red {
      float: right;
      margin-top: 25px; }
    .video_block {
      background-color: #aaa;
      width: 85%;
      float: right;
      margin-top: 68px;
      height: 384px;
      clear: right; } }
  .block_right {
    margin-top: 10px;
    padding-left: 22px;
    padding-right: 0; } }
#content4 {
  padding-top: 300px;
  .container {
    padding-left: 0; }
  .block_left {
    padding-left: 0;
    padding-right: 55px;
    position: relative;
    .title_block.title_cnt4 {
      text-align: right;
      color: #c2799b;
      margin-right: 5px; }
    .img_block {
      float: right;
      margin-right: 15px;
      margin-top: 29px; }
    .txt_block.border_block {
      position: absolute;
      bottom: 190px;
      width: 220px;
      height: 170px;
      padding: 15px 20px;
      left: 0; } }
  .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: -5px;
    padding-top: 1px;
    padding-right: 0; }
  hr.red {
    margin-top: 20px; }
  .cnt4_text {
    margin-top: 40px;
    p {
      margin-bottom: 8px; } }
  ul.cnt4_list {
    li {
      margin-bottom: 6px; } } }
#content5 {
  padding-top: 346px;
  .block_left {
    padding-left: 0;
    padding-bottom: 291px;
    .subtitle.subtitle_cnt5 {
      width: 60%;
      text-align: left;
      color: #188e9e; }
    hr.red {
      margin-top: 36px;
      float: left; }
    .cnt5_text {
      float: left;
      width: 60%;
      padding-left: 63px;
      margin-top: 30px;
      color: #5a5a5a; }
    .txt_block.border_block {
      right: -20px;
      width: 218px;
      bottom: 225px;
      height: 120px;
      padding: 20px;
      color: #5a5a5a; } } }
#content6 {
  .container {
    position: relative; }
  .block_right {
    margin-top: -82px;
    padding-left: 20px;
    padding-bottom: 290px;
    .subtitle.subtitle_cnt6 {
      color: #188e9e; }
    hr.red {
      margin-top: 35px; }
    .cnt6_text {
      margin-top: 52px;
      color: #5a5a5a; } }
  .txt_block.border_block {
    bottom: 190px;
    left: 105px;
    width: 235px;
    height: 82px;
    padding: 15px 30px 15px 50px;
    color: #5a5a5a; } }
#content7 {
  .title_block.title_cnt7 {
    text-align: center;
    color: #76c7d3; }
  .cnt7_content {
    padding-top: 55px;
    padding-bottom: 170px;
    color: #fff; }
  .block_top,.block_bottom {
    padding: 0 10px;
    &:first-child {
      padding-left: 15px; }
    &:nth-child(2) {
      padding-left: 15px; }
    &:last-child {
      padding-right: 0;
      padding-left: 15px; } }
  .title7 {
    font-family: "Gilroy Light";
    font-size: 20px;
    line-height: 24px;
    p {
      margin-bottom: 5px; }
    hr.red {
      margin-top: 20px;
      margin-bottom: 25px; } }
  .text7 {
    margin-top: 13px;
    p {
      margin-bottom: 8px;
      line-height: 22px;
      font-family: "Rubik Light";
      font-size: 15px; } }
  .block7_2 {
    margin-top: 44px; }
  .block_bottom.col-md-4.last_block {
    margin-top: -59px; } }
#content8 {
  .form_block {
    margin: -105px 0 130px -71px; } }
#content9 {
  .block_left {
    padding-left: 0;
    .title_block.title_cnt9 {
      color: #76c7d3;
      margin-top: -15px;
      margin-left: -10px; }
    .subtitle.subtitle_cnt9 {
      color: #188e9e;
      margin-top: 42px; }
    hr.red {
      margin-top: 19px;
      float: left; }
    .cnt9_text {
      float: left;
      width: 65%;
      padding-left: 64px;
      padding-top: 13px;
      color: #5a5a5a;
      p {
        margin-bottom: 9px; }
      ul.cnt9_list {
        line-height: 22px;
        li {
          margin-bottom: 8px; } } } }
  .block_right {
    margin-top: 130px;
    padding-bottom: 185px;
    img {
      float: right;
      margin-right: -15px; }
    .txt_block.border_block {
      left: -75px;
      width: 220px;
      top: 240px;
      height: 195px;
      color: #5a5a5a;
      padding: 30px; } } }
#content10 {
  .block_left {
    padding-bottom: 318px;
    .video_block {
      background-color: #aaa;
      width: 94%;
      margin-top: 20px;
      height: 384px;
      margin-left: 5px; } }
  .block_right {
    padding-left: 20px;
    .subtitle.subtitle_cnt10 {
      color: #188e9e;
      margin-top: -75px; }
    hr.red {
      margin-top: 40px; }
    .cnt10_text {
      margin-top: 50px; } } }
#content11 {
  .title_block.title_cnt11 {
    text-align: right;
    color: #c2799b;
    margin-top: -100px;
    padding-right: 0; }
  .cnt11_content {
    margin-top: 115px; }
  .block_left {
    padding-left: 0;
    padding-bottom: 240px; }
  .subtitle.subtitle_cnt11 {
    color: #fff;
    margin-top: 15px; }
  hr.red {
    margin-top: 20px; }
  .cnt11_text {
    margin-top: 30px;
    color: #fff; } }
#content12 {
  .form_block {
    margin: -100px 0 0 -72px;
    padding-top: 115px;
    padding-bottom: 85px;
    margin-bottom: 122px;
    .form_subtitle {
      width: 75%; }
    .form_btm {
      width: 100%;
      a.btn_style2 {
        margin: 0 auto;
        width: 65%;
        display: block;
        margin-top: 70px;
        padding: 15px 30px;
        height: 50px;
        margin-bottom: 18px; } } }
  .right_block {
    margin-top: -145px;

    p.txt {
      color: #fff;
      padding-left: 95px;
      font-size: 12px;
      text-align: left;
      line-height: 22px; } } }
#content13 {
  .title_block.title_cnt13 {
    padding-top: 10px;
    width: 90%;
    color: #76c7d3; }
  .cnt13_blocks {
    margin-top: 45px;
    padding-bottom: 265px;
    .cnt13_block {}
    .name_block {
      margin-top: 15px;
      a {
        font-family: "Gilroy Light";
        color: #188e9e;
        font-size: 20px;
        text-align: left;
        line-height: 22px;
        transition: .5s;
        &:hover {
          text-decoration: none;
          color: #5a5a5a; } } }
    .subtitle_block {
      margin-top: 8px;
      p {
        font-family: "Rubik Light";
        font-size: 14px;
        text-align: left;
        color: #5a5a5a;
        line-height: 22px;
        margin-bottom: 0; } }
    .second_row {
      margin-top: 38px; } } }
#content14 {
  .subtitle.subtitle_cnt14 {
    color: #188e9e; }
  hr.red {
    margin-top: 20px; }
  .cnt14_content {
    margin-top: 50px;

    .cnt14_block {
      margin-bottom: 216px;
      &:first-child {
        padding-left: 0; } }
    p.text {
      margin-top: 50px; } } }
#content15 {
  .form_block {
    margin: -100px 0 0 -75px;
    margin-bottom: 125px;
    .form_title {
      width: 95%;
      margin: 0 auto;
      text-align: center; } }
  .form_block form .input-group #phone_cnt15 {
    float: right; }
  .form_block form .input-group #name_cnt15, .form_block form .input-group #phone_cnt15 {
    width: 49%; }
  .right_block {
    p.txt {
      color: #fff;
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      display: block;
      padding-left: 100px;
      margin-top: 28px; } } }
#content16 {
  .subtitle.subtitle_cnt16 {
    margin-top: -124px;
    color: #188e9e;
    padding-left: 15px;
    padding-right: 0; }
  hr.red {
    margin-top: 35px; }
  .cnt16_content {
    margin-top: 40px;

    .cnt16_block {
      border: 5px solid transparent;
      padding-top: 50px;
      min-height: 385px;
      transition: .5s;
      cursor: pointer;
      margin-bottom: 168px;
      &:hover {
        border: 5px solid #cbeef3; }
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 45px; }
      .descr {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;
        p.cnt16_text {
          font-style: italic; } } } } }
#content17 {
  background-image: url(../images/bg17.jpg);
  background-size: 100%;
  background-position: bottom;
  max-height: 100%;
  .title_block.title_cnt17 {
    padding-left: 0;
    p {
      color: #76c7d3; } }
  #answers {
    width: 100%;
    float: left;
    margin-top: 40px;
    margin-bottom: 310px;
    .owl-carousel .owl-stage-outer {
      padding: 10px 20px; }
    .slide {
      img {
        box-shadow: 0px 15px 40px -20px #dadada;
        width: 80%;
        margin: 0 auto;
        border: 1px solid #eee; }
      a {
        color: #5a5a5a;
        &:hover {
          text-decoration: none; } } }
    p.slide_descr {
      margin-top: 48px;
      padding-left: 25px; } } }
#content18 {
  .form_block {
    margin: 0 auto;
    float: none;
    margin-top: -138px;
    padding-top: 120px;
    padding-bottom: 50px;
    margin-bottom: 160px;
    .form_title {
      width: 60%;
      margin: 0 auto;
      position: relative;
      top: -50px; }
    .form_subtitle {
      position: relative;
      top: -50px;
      width: 60%; }
    form {
      top: -50px;
      position: relative;
      input {
        width: 49%; } }
    #mail_cnt18 {
      float: right; }
    #name_cnt18 {
      width: 100%; }
    #mail_cnt18, #phone_cnt18 {
      margin-top: 16px; }
    #btn_cnt18 {
      padding: 15px 20px;
      height: 50px; }
    p.btn_txt {
      width: 85%;
      margin: 0 auto;
      margin-top: 10px; } } }
#content19 {
  .block_left {
    padding-left: 0;
    .question {
      margin-top: 40px;
      p {
        text-align: left;
        font-family: "Rubik Light";
        font-size: 20px;
        color: #5a5a5a;
        line-height: 34px;
        margin-bottom: 0; }
      a.quest.btn_style2 {
        height: 50px;
        display: block;
        width: 320px;
        margin-top: 3px; } }
    ul.map_list {
      list-style-type: none;
      margin-top: 75px;
      padding-left: 0;
      margin-left: -55px;
      margin-right: -90px;
      position: relative;
      z-index: 9;
      li {
        border: 5px solid transparent;
        padding: 22px 48px;
        transition: .5s;
        margin-bottom: 20px;
        &:hover {
          border: 5px solid #cbeef3;
          cursor: pointer; }
        &.active {
          border: 5px solid #cbeef3; }
        .map_title {
          font-family: "Gilroy Light";
          color: #000;
          font-size: 26px;
          text-align: left;
          line-height: 34px;
          width: 100%;
          p {
            margin-bottom: 0; } }
        .map_adress {
          font-family: "Rubik Light";
          font-size: 20px;
          margin-top: 2px;
          color: #5a5a5a;
          line-height: 34px;
          text-align: left;
          width: 100%;
          p {
            margin-bottom: 0; } }
        .map_phone {
          font-family: "Rubik Medium";
          color: #000;
          font-size: 28px;
          line-height: 34px; }
        .map_btn {
          margin-top: 15px;
          max-width: 330px;
          a.mapbtn.btn_style {
            height: 45px;
            display: block;
            padding: 8px 20px;
            transition: .5s;
            border: 3px solid transparent;
            &:hover {
              border: 3px solid #56dee0;
              background-color: #63b1bc; } } } } } }
  .block_right {
    padding-right: 0;
    .title_block.title_cnt19 {
      color: #76c7d3;
      margin-top: -70px;
      text-align: right; }
    hr.red {
      float: right;
      margin-top: 4px;
      clear: left; }
    .subtitle.subtitle_cnt19 {
      float: right;
      clear: right;
      text-align: right;
      margin-top: 20px; }
    #map.map_block {
      background-color: #aaa;
      height: 595px;
      width: 100%;
      float: right;
      margin-top: 25px; } } }
footer {
  padding: 15px;
  padding-top: 15px;
  padding-top: 140px;
  height: 211px;
  .logo {
    float: left; }
  .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 24px;
    float: left;
    line-height: 20px;
    padding-top: 5px; }
  .f_inst {
    float: left;
    a {
      color: #5a5a5a;
      float: right;
      width: 47%;
      &:hover {
        text-decoration: none; }
      img {
        float: left;
        margin-top: 10px;
        margin-right: 20px; }
      p {
        display: block; } } }
  .f_orange_logo {
    a {
      color: #5a5a5a;
      float: right;
      width: 80%;
      &:hover {
        text-decoration: none; }
      img {
        float: left;
        margin-right: 20px; }
      p {
        float: left;
        display: block;
        width: 40%; } } }
  .f_callback {
    float: right;
    margin-top: 10px; } }
/* modal */
.modal .modal-dialog {
  width: 100%; }
.modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0; }
.modal .close {
  opacity: 1;
  right: 40px;
  top: 40px;
  z-index: 99;
  position: absolute; }
.modal .modal-header {
  border-bottom: none;
  position: absolute;
  width: 100%; }
.modal .modal_right {
  border: 5px solid #e4428c; }
.modal .title {
  color: #fff;
  font-family: "Gilroy ExtraBold";
  font-size: 40px;
  line-height: 42px;
  text-transform: uppercase;
  text-align: center; }
.modal .subtitle {
  font-family: "Gilroy Light";
  font-size: 26px;
  text-align: center;
  color: #fff;
  line-height: 45px; }
.modal p.modal_text {
  color: #fff;
  font-family: "Rubik Light";
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px; }
.modal-backdrop {
  background-color: #203f47; }
.modal-backdrop.in {
  opacity: .9; }
#back_call_modal {
  .modal-dialog {
    max-width: 1014px; }
  .modal_right {
    padding: 70px; }
  .modal_left {
    padding-top: 50px;
    img {
      margin-left: -30px; } }
  p.modal_text {
    margin-left: -30px;
    padding-right: 30px; }
  .title {
    width: 80%;
    margin: 0 auto; }
  .subtitle {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px; }
  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px; }
      #name_bcm1, #phone_bcm1 {
        width: 49%; }
      #phone_bcm1 {
        float: right; }
      textarea {
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin-top: 15px; } } } }
#thanks_modal {
  margin-top: 50px;
  .modal-dialog {
    max-width: 900px; }
  .thr_right {
    border: 5px solid #e4428c;
    padding: 50px; }
  .title {
    width: 95%;
    margin: 0 auto;
    margin-top: 40px; }
  .thx_left {
    img {
      margin-top: -40px;
      margin-left: -90px; } }
  #modal_text {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
    margin-left: -90px; } }
#implant_modal {
  margin-top: 50px;
  .modal-dialog {
    max-width: 1105px; }
  .modal_right {
    padding: 50px 30px; }
  .modal_left {
    img {
      margin-top: 50px; } }
  .subtitle {
    width: 80%;
    margin: 0 auto;
    line-height: 30px;
    margin-top: 20px; }
  p.modal_text {
    padding-right: 30px; }
  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px;
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000; }
      #name_imp1, #phone_imp1 {
        width: 49%; }
      #phone_imp1 {
        float: right; }
      textarea {
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000;
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        padding: 5px 20px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin-top: 15px; } } } }
#free_modal {
  margin-top: 50px;
  .modal-dialog {
    max-width: 1105px; }
  .modal_right {
    padding: 50px 30px; }
  .modal_left {
    img {
      margin-top: 50px; } }
  .subtitle {
    width: 80%;
    margin: 0 auto;
    line-height: 30px;
    margin-top: 20px; }
  p.modal_text {
    padding-right: 30px; }
  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px;
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000; }
      #name_f1, #phone_f1 {
        width: 49%; }
      #phone_f1 {
        float: right; }
      textarea {
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000;
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        padding: 5px 20px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin-top: 15px; } } } }
#doctor_modal {
  .modal-dialog {
    max-width: 1015px; }
  .modal_left {
    img {
      margin-top: 50px;
      margin-left: -30px; }
    p.modal_text {
      margin-left: -30px; } }
  .modal_right {
    padding: 60px;
    padding-bottom: 40px; }
  .subtitle {
    line-height: 30px;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; }
  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px;
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000; }
      #name_d1, #phone_d1 {
        width: 49%; }
      #phone_d1 {
        float: right; }
      textarea {
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000;
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        padding: 5px 20px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin: 0 auto;
        margin-top: 15px;
        width: 80%; } } } }
#info_modal {
  .modal-dialog {
    max-width: 680px; }
  .modal-body {
    padding: 50px;
    border: 5px solid #e4428c; }
  #info_title {
    color: #fff;
    font-family: "Gilroy ExtraBold";
    font-size: 40px;
    line-height: 42px;
    text-transform: uppercase;
    text-align: center; }
  #info_subtitle {
    font-family: "Gilroy Light";
    font-size: 26px;
    text-align: center;
    color: #fff;
    line-height: 30px;
    margin-top: 20px; }
  #info_text {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px; }
  .close {
    top: 25px;
    right: 25px; }
  .info_btn {
    margin-top: 25px;
    a.btn_left {
      float: left; }
    a.btn_right {
      float: right; } } }
#design_modal {
  .modal-dialog {
    max-width: 1105px; }
  .modal_right {
    padding: 50px 30px;
    padding-top: 80px; }
  .modal_left {
    img {
      margin-top: 50px; } }
  .subtitle {
    width: 80%;
    margin: 0 auto;
    line-height: 30px;
    margin-top: 20px; }
  p.modal_text {
    padding-right: 30px; }
  form {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px;
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000; }
      #name_ds1, #phone_ds1 {
        width: 49%; }
      #phone_ds1 {
        float: right; }
      textarea {
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000;
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        padding: 5px 20px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin: 0 auto;
        margin-top: 15px;
        width: 80%; } } } }
#question_modal {
  .modal-dialog {
    max-width: 1105px; }
  .modal_right {
    padding: 50px 30px;
    padding-top: 80px; }
  .modal_left {
    img {
      margin-top: 50px; } }
  .subtitle {
    width: 80%;
    margin: 0 auto;
    line-height: 30px;
    margin-top: 20px; }
  p.modal_text {
    padding-right: 30px; }
  form {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    .input-group {
      width: 100%;
      input {
        height: 50px;
        border-radius: 50px;
        border: none;
        padding-left: 25px;
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000; }
      #name_q1, #phone_q1 {
        width: 49%; }
      #phone_q1 {
        float: right; }
      textarea {
        font-family: "Rubik Light";
        font-size: 16px;
        color: #000;
        height: 50px;
        border-radius: 50px;
        border: none;
        width: 100%;
        padding-left: 25px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px; }
      button {
        border: none;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        padding: 5px 20px; }
      p.btn_txt {
        color: #fff;
        font-family: "Rubik Light";
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        margin: 0 auto;
        margin-top: 15px;
        width: 80%; } } } }
/* adaptive */
@media (width: 1600px) {
  header .h_metro {
    padding-left: 80px; }
  #content18 .form_block {
    padding-top: 10px; }
  #content18 .form_block .form_title,#content18 .form_block .form_subtitle {
    top: 50px; }
  #content18 .form_block form {
    top: 25px; }
  footer {
    .col-md-3 {
      width: 27%; } } }
@media (width: 1366px) {
  #content17 {
    -webkit-background-size: 119% !important;
    background-size: 119% !important; } }
@media (min-width: 1330px) and (max-width: 1370px) {
  header {
    height: 100px; }
  header .h_metro {
    padding-left: 10px; }
  header .h_callback {
    width: 20%; }
  header .h_callback a.number {
    padding-top: 0; }
  header .h_callback a.callbtn {
    margin-right: 20px;
    margin-top: 10px; }
  #content17 {
    -webkit-background-size: 122%;
    background-size: 122%; }
  #content18 .form_block .form_title,#content18 .form_block .form_subtitle {
    top: 50px; }
  #content18 .form_block form {
    top: 25px; }
  footer .f_inst a {
    width: 90%; }
  footer .f_orange_logo a {
    width: 100%; }
  footer {
    .col-md-3 {
      width: 20%;
      padding: 0; } }
  footer .f_orange_logo a p {
    width: 55%; } }


@media (width: 1280px) {
  header {
    height: 100px; }
  header .h_metro {
    padding-left: 10px; }
  header .h_callback {
    width: 20%; }
  header .h_callback a.number {
    padding-top: 0; }
  header .h_callback a.callbtn {
    margin-right: 20px;
    margin-top: 10px; }
  #content17 {
    -webkit-background-size: 127%;
    background-size: 127%; }
  #content18 .form_block .form_title,#content18 .form_block .form_subtitle {
    top: 50px; }
  #content18 .form_block form {
    top: 25px; }
  footer .f_inst a {
    width: 90%; }
  footer .f_orange_logo a {
    width: 100%; }
  footer {
    .col-md-3 {
      width: 20%;
      padding: 0; } }
  footer .f_orange_logo a p {
    width: 55%; } }
@media (width: 1200px) {
  header {
    height: 100px; }
  header .h_metro {
    padding-left: 10px; }
  header .h_callback {
    width: 20%; }
  header .h_callback a.number {
    padding-top: 0; }
  header .h_callback a.callbtn {
    margin-right: 20px;
    margin-top: 10px; }
  #content17 {
    -webkit-background-size: 135%;
    background-size: 135%; }
  #content18 .form_block .form_title,#content18 .form_block .form_subtitle {
    top: 50px; }
  #content18 .form_block form {
    top: 25px; }
  footer .f_inst a {
    width: 90%; }
  footer .f_orange_logo a {
    width: 100%; }
  footer {
    .col-md-3 {
      width: 20%;
      padding: 0; } }
  footer .f_orange_logo a p {
    width: 52%; }
  .owl-next {
    margin-right: -15px; }
  .owl-prev {
    margin-left: -15px; }
  header .h_text {
    padding-left: 15px;
    font-size: 14px; }
  header .h_callback a.number {
    font-size: 25px; } }
@media (width: 1024px) {
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 15px; }
  .cn11_img {
    max-width: 448px;
    height: 234px; }
  #thanks_modal {
    .modal-dialog .modal-body {
      margin-left: 8%; } }
  #content13 .title_block.title_cnt13 {
    padding-top: 25px; }
  header {
    padding-bottom: 10px;
    height: 90px; }
  header .h_text {
    font-family: Gilroy ExtraBold;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    padding-left: 20px;
    float: left;
    line-height: 20px;
    padding-top: 0;
    width: 17%; }
  header .h_metro {
    padding-left: 0;
    width: 20%; }
  header .h_callback {
    width: 21%; }
  header .h_time {
    width: 25%;
    font-size: 14px;
    padding-top: 5px; }
  header .h_callback a.number {
    font-size: 22px;
    padding-top: 0;
    margin-right: 5px;
    text-align: right;
    float: right; }
  header .btn_style {
    padding: 11px 20px; }
  #video .main_block .redb_block .video_title {
    font-size: 55px; }
  #video .main_block .redb_block .cnt1_write {
    width: 100%; }
  #content1 {
    padding-top: 390px; }
  #content4 {
    padding-top: 255px; }
  #content5 .block_left {
    padding-bottom: 165px; }
  #content5 .block_left .txt_block.border_block {
    right: -45px;
    width: 218px;
    bottom: 270px; }
  #content6 .block_right {
    margin-top: -145px; }
  #content6 .block_right {
    padding-bottom: 220px; }
  #content7 .img7 img {
    width: 100%; }
  #content7 .title7 {
    font-size: 16px; }
  .form_block .form_title {
    font-size: 45px;
    width: 80%;
    margin: 0 auto; }
  .right_block {
    img {
      width: 125%; } }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 80%;
    padding-left: 25px;
    padding-top: 13px;
    color: #5a5a5a; }
  #content9 .block_right .txt_block.border_block {
    left: 0;
    width: 220px;
    top: -220px;
    height: 195px;
    color: #5a5a5a;
    padding: 30px; }
  .cn11_img img {
    width: 100%;
    height: 235px; }
  #content11 {
    .subtitle p {
      font-size: 22px; } }
  #content11 .cnt11_text {
    font-size: 14px; }
  #content12 {
    .form_block .form_subtitle {
      font-size: 22px; } }
  #content12 .form_block {
    margin-bottom: 70px; }
  #content13 {
    min-height: 1465px; }
  #content14 {
    margin-top: -45px;
    img.cnt11_img {
        width: 100%; } }
  .owl-prev {
    margin-left: -20px; }
  .owl-next {
    margin-right: -20px; }
  #content16 .cnt16_content .cnt16_block .descr {
    width: 100%;
    padding-left: 30px;
    padding-right: 0;
    margin: 0 auto; }
  #content16 .cnt16_content .cnt16_block:nth-child(2) .descr {
    padding-left: 5px; }
  #content17 {
    -webkit-background-size: 174%;
    background-size: 174%; }
  #content18 .form_block .form_title,#content18 .form_block .form_subtitle {
    top: 50px; }
  #content18 .form_block form {
    top: 25px; }
  #content19 .block_left ul.map_list li {
    padding: 22px 30px; }
  footer .f_inst a {
    width: 100%; }
  footer .f_orange_logo a {
    width: 100%; }
  footer {
    .col-md-3 {
      width: 22%;
      padding: 0; } }
  footer .f_orange_logo a p {
    width: 52%; }
  footer .f_text {
    width: 18%;
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 24px;
    float: left;
    line-height: 18px;
    padding-top: 0; }
  footer .f_orange_logo a p {
    width: 48%;
    font-size: 14px;
    line-height: 18px;
    padding-top: 10px; } }
@media (width: 768px) {
  .form_block form .input-group input {
    height: 50px;
    border-radius: 50px;
    border: none;
    font-size: 14px;
    padding-left: 15px; }
  .modal .modal_left {
    display: none; }
  #video {
    background-image: url(../images/main_bg.jpg);
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-size: cover; }
  #bg_block {
    display: none; }
  .cn11_img {
    max-width: 448px;
    height: 202px; }
  #thanks_modal .thx_left img {
    margin: 0; }
  #back_call_modal .modal_left {
    display: none; }
  #thanks_modal #modal_text {
    margin-left: 0; }
  header {
    height: 100px;
    .logo {
      width: 100px;
      img {
        width: 100%; } }
    .h_text {
      font-size: 12px;
      float: left;
      clear: left;
      padding-left: 0;
      width: 30%;
      line-height: 15px;
      padding-top: 12px; } }
  header .h_metro {
    float: left;
    padding-left: 45px;
    font-size: 12px;
    line-height: 20px;
    width: 21%;
    margin-top: -40px; }
  header .h_time {
    float: left;
    font-size: 12px;
    line-height: 16px;
    width: 28%;
    position: absolute;
    left: 280px;
    top: 58px; }
  header .h_callback {
    float: right;
    width: 40%;
    margin-top: -40px; }
  header .h_callback a.number {
    width: 100%;
    text-align: right;
    font-size: 22px;
    padding-right: 20px;
    padding-bottom: 5px; }
  #video .main_block .redb_block .video_title {
    font-size: 55px; }
  .subtitle p {
    font-size: 18px;
    line-height: 26px;
    font-family: Gilroy Light; }
  .cnt1_write img {
    width: 16%; }
  .cnt1_write img.block3 {
    width: 25%;
    padding-top: 5px; }
  #video .main_block .redb_block .cnt1_write {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write p {
    font-size: 16px; }
  #content1 {
    padding-top: 370px; }
  #content1 .block_left {
    padding-right: 0; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 50%;
    margin-top: 40px;
    height: 255px;
    float: left; }
  #content1 .block_right {
    margin-left: 0;
    padding-top: 34px;
    padding-right: 0;
    color: #fff;
    float: left;
    width: 50%; }
  #content2 {
    padding-top: 240px;
    background-position: 58% 0px; }
  #content2 .block_left {
    padding-left: 0;
    width: 65%; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 81%;
    padding-left: 0;
    padding-top: 28px;
    padding-right: 90px; }
  #content3 {
    padding-top: 185px;
    background-position: 58% 0%; }
  #content3 .block_left {
    padding-right: 0;
    width: 50%;
    float: left; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: right;
    margin-top: 68px;
    height: 285px;
    clear: right; }
  #content3 .block_right {
    margin-top: 155px;
    padding-left: 22px;
    padding-right: 0;
    float: left;
    width: 50%; }
  #content13 .title_block.title_cnt13 {
    padding-top: 25px; }
  #content4 {
    padding-top: 245px; }
  .title_block {
    font-family: Gilroy ExtraBold;
    line-height: 50px;
    font-size: 40px;
    text-transform: uppercase; }
  #content1 .block_left .title_block.title_cnt1 {
    color: #76c6d2;
    text-align: right;
    width: 80%;
    float: right;
    margin-right: 30px; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: -75px;
    margin-top: 201px;
    width: 40%;
    img {
      width: 100%; } }
  #content4 .block_right {
    color: #fff;
    padding-left: 40px;
    margin-left: -5px;
    padding-top: 1px;
    padding-right: 0;
    float: left;
    width: 65%; }
  #content4 .cnt4_text {
    margin-top: 20px;
    font-size: 14px;
    padding-right: 20px; }
  #content4 .block_left .txt_block.border_block {
    position: absolute;
    bottom: 0;
    width: 220px;
    height: 170px;
    padding: 15px 20px;
    top: 225px;
    right: 0;
    left: initial; }
  #content5 .block_left .cnt5_text {
    float: left;
    width: 45%;
    padding-left: 25px;
    margin-top: 30px;
    color: #5a5a5a; }
  #content5 .block_left .cnt5_text {
    float: left;
    width: 45%;
    padding-left: 0;
    margin-top: 30px;
    color: #5a5a5a;
    padding-bottom: 120px;
    clear: left; }

  #content5 .block_left .subtitle.subtitle_cnt5 {
    width: 60%;
    text-align: left;
    color: #188e9e;
    margin-top: -135px; }
  #content5 .block_left .txt_block.border_block {
    right: 70px;
    width: 218px;
    bottom: -65px;
    height: 120px;
    padding: 20px;
    color: #000;
    background-color: rgba(255,255,255,0.7); }
  #content6 .block_right {
    margin-top: -85px;
    padding-left: 20px;
    padding-bottom: 233px;
    float: right;
    width: 70%;
    text-align: right; }
  #content6 .block_right .subtitle.subtitle_cnt6 {
    color: #188e9e;
    width: 50%;
    float: right; }
  #content6 .block_right hr.red {
    margin-top: 0;
    float: right;
    clear: right; }

  #content6 .block_right .cnt6_text {
    margin-top: 15px;
    color: #5a5a5a;
    float: right;
    width: 100%;
    padding-left: 24%; }

  #content7 .col-md-4 {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content7 .text7 p {
    margin-bottom: 8px;
    line-height: 18px;
    font-family: "Rubik Light";
    font-size: 12px; }
  #content7 .title7 p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 20px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: -40px; }
  .form_block .form_title {
    font-family: "Gilroy ExtraBold";
    font-size: 31px;
    line-height: 38px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  .form_block .form_subtitle {
    font-family: "Gilroy Light";
    line-height: 25px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  .form_block form {
    width: 75%;
    margin: 0 auto;
    margin-top: 40px; }
  .check_group {
    margin-top: 0px;
    width: 100%;
    margin-left: 5px;
    float: none;
    padding-top: 10px; }
  .form_block form .input-group p.btn_txt {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 13px;
    text-align: center;
    line-height: 22px;
    margin-top: 15px; }
  #content8 .right_block img {
    width: 120%; }
  #content8 .right_block .r_text {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    padding-left: 60px;
    margin-top: 20px;
    width: 120%; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 60%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 .block_right {
    margin-top: 0;
    padding-bottom: 185px;
    width: 40%;
    float: left;
    img {
      width: 100%; } }
  #content9 .block_right .txt_block.border_block {
    left: -20px;
    width: 220px;
    top: 60px;
    height: 195px;
    color: #000;
    padding: 30px;
    background-color: rgba(255,255,255,0.7); }
  #content10 .block_left {
    padding-bottom: 318px;
    width: 50%;
    float: left; }
  #content10 .block_right {
    padding-left: 20px;
    float: left;
    width: 50%; }
  #content10 .block_right .cnt10_text {
    margin-top: 20px; }
  #content10 .block_left .video_block {
    background-color: #aaa;
    width: 94%;
    margin-top: 20px;
    height: 225px;
    margin-left: 5px; }
  #content11 .block_left {
    padding-left: 0;
    padding-bottom: 240px;
    width: 50%;
    float: left;
    img {
      width: 100%; } }
  #content11 .block_right.col-md-6 {
    float: left;
    width: 50%;
    img {
      width: 100%; } }
  #content11 .cnt11_content {
    margin-top: 25px; }
  #content12 .right_block p.txt {
    color: #fff;
    padding-left: 80px;
    font-size: 12px;
    text-align: left;
    line-height: 22px;
    width: 115%; }
  #content12 .right_block img {
    width: 120%; }
  #content12 .form_block .form_subtitle {
    width: 80%;
    font-size: 18px; }
  #content12 .form_block .form_btm a.btn_style2 {
    width: 80%; }
  #content13 .cnt13_block.col-md-4 {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content13 .cnt13_blocks .name_block a {
    font-family: "Gilroy Light";
    color: #188e9e;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    transition: .5s; }
  #content13 .cnt13_blocks .subtitle_block p {
    font-size: 14px; }
  #content14 {
    margin-top: -40px; }
  #content14 .cnt14_content .cnt14_block {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 15px; }
  #content14 .cnt14_content p.text {
    margin-top: 20px;
    font-size: 14px; }
  #content15 .right_block img {
    width: 120%; }
  #content15 .right_block p.txt {
    color: #fff;
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    display: block;
    padding-left: 70px;
    margin-top: 28px;
    width: 115%; }
  #content16 .subtitle.subtitle_cnt16 {
    margin-top: -40px; }
  #content16 .cnt16_block.col-md-4 {
    width: 33.3%;
    float: left; }
  #content16 .cnt16_content .cnt16_block img {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 30px; }
  #content16 .cnt16_content .cnt16_block .descr {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    font-size: 13px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 50px;
    min-height: 350px;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 134px; }
  #content17 #answers {
    width: 90%;
    float: none;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 220px; }
  #content18 .form_block {
    margin-top: -75px;
    padding-top: 0; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 50px; }
  #content17 {
    -webkit-background-size: 227%;
    background-size: 227%; }
  #content19 .block_left {
    padding-left: 0;
    width: 50%;
    float: left; }
  #content19 .block_right {
    padding-right: 0;
    width: 50%;
    float: left; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: -70px;
    text-align: right;
    width: 120%;
    margin-left: -20%; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 16px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 25px;
    text-align: left;
    width: 100%; }
  footer {
    padding-top: 20px;
    height: 135px; }
  footer .f_inst {
    float: left;
    clear: left;
    width: 30%;
    padding-left: 0;
    padding-top: 10px;
    a {
      width: 100%;
      p {
        margin: 0; } } }
  footer .f_orange_logo.col-md-3 {
    padding-top: 10px;
    float: left;
    width: 35%;
    a {
      width: 100%; } }
  footer .f_orange_logo a p {
    float: left;
    margin: 0;
    display: block;
    width: 50%;
    font-size: 15px; }
  #content19 .block_right #map.map_block {
    height: 510px; }
  footer .f_callback {
    float: right;
    margin-top: -40px; } }
@media (width: 320px) {
  .right_block, .cn11_img, .cnt11_img, .img7 {
    img {
      width: 100%; } }
  img.cnt11_img {
    width: 100%; }


  .owl-next {
    margin-right: 0; }
  .owl-prev {
    margin-left: 0; }
  header .h_metro {
    padding-left: 0; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 100%; }
  #content19 .block_left ul.map_list {
    list-style-type: none;
    margin-top: 75px;
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
    z-index: 9; }
  #content6 .txt_block.border_block {
    left: 0; }
  #content19 .block_left .question a.quest.btn_style2 {
    width: 100%; }
  #content5 .block_left .cnt5_text {
    padding-left: 0;
    float: left;
    clear: left;
    width: 100%; }
  #video #bg_block {
    display: none; }

  header {
    min-height: 320px; }
  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 40%;
      margin: 0 auto; } }
  header .h_text {
    font-size: 14px;
    padding-left: 0;
    float: left;
    line-height: 16px;
    padding-top: 12px;
    text-align: center;
    width: 100%; }
  header .h_metro {
    padding-left: 0;
    width: 55%;
    margin: 0 auto;
    float: none; }
  header .h_time {
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    display: block; }
  header .h_callback {
    float: left;
    width: 100%; }
  header .h_callback a.number {
    text-decoration: none;
    color: #000;
    font-family: Rubik Medium;
    font-size: 28px;
    line-height: 20px;
    cursor: default;
    margin-right: 0;
    padding-top: 15px;
    display: block;
    float: none;
    width: 100%;
    text-align: center; }
  header .h_callback a.callbtn {
    float: left;
    margin-top: 10px;
    width: 100%; }
  #video .main_block {
    padding: 0; }
  #video .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 100%;
    position: relative;
    z-index: 9; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 28px;
    line-height: 35px;
    padding-top: 30px; }
  .subtitle p {
    font-size: 20px;
    line-height: 22px; }
  #video .main_block .redb_block .cnt1_subblock {
    margin-top: 30px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write {
    width: 75%;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 10px;
    margin-bottom: 50px;
    padding-right: 25px; }
  #video .main_block .redb_block .cnt1_write img {
    margin-top: 0;
    display: inline-block; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 0;
    margin-right: 0; }
  #content1 {
    margin-top: -90px;
    padding-top: 225px; }
  #content1 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .title_block {
    font-size: 28px;
    line-height: 35px; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    margin-top: 15px;
    height: 175px; }
  #content1 .block_right .subtitle.subtitle_cnt1 {
    width: 100%;
    padding-left: 10px;
    margin-top: 15px; }
  #content1 .block_right hr.red {
    margin-top: 10px;
    margin-bottom: 10px; }
  #content1 .cnt1_text {
    padding-left: 10px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px; }
  #content2 .block_left hr.red {
    margin-top: 0px;
    float: left; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 15px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px;
    background-position: 42% 0; }
  #content3 {
    padding-top: 260px;
    background-position: 45% 0;
    padding-bottom: 715px; }
  #content3 .block_left .subtitle.subtitle_cnt3 p {
    text-align: left;
    color: #188e9e; }
  #content3 .block_left hr.red {
    float: left;
    margin-top: 15px; }
  #content3 .block_left {
    padding-right: 0;
    padding-left: 0; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: left;
    margin-top: 25px;
    height: 218px;
    clear: right; }
  #content3 .block_right {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 0px;
    float: left;
    background-color: rgba(255,255,255,.9);
    width: 100%; }
  #content3 .cnt3_text {
    padding-top: 10px; }
  #content4 {
    padding-top: 185px; }
  #content4 .block_left {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  #content4 .block_left .title_block.title_cnt4 {
    font-size: 26px; }
  #content4 .block_left {
    position: relative; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 15px;
    margin-top: 29px;
    position: absolute;
    width: 100%; }
  #content4 .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: 11px;
    padding-top: 1px;
    padding-right: 0; }
  #content4 .cnt4_text {
    margin-top: 10px; }
  #content4 .block_left .img_block {
    display: none; }
  #content5 {
    padding-top: 210px; }
  #content5 .block_left hr.red {
    margin-top: 10px;
    float: left; }
  #content4 .block_left .txt_block.border_block {
    display: none; }
  #content5 .block_left .txt_block.border_block {
    display: none; }
  #content4 {
    max-height: 790px; }
  #content5 {
    background-size: 665%;
    background-position: 30% 0%;
    padding-top: 285px;
    padding-bottom: 20px; }
  #content6 {
    background-image: none; }
  #content6 .block_right {
    margin-top: 35px;
    padding-left: 0;
    padding-bottom: 10px; }
  #content6 .txt_block.border_block {
    display: none; }
  #content6 .block_right hr.red {
    margin-top: 10px; }
  #content6 .block_right .cnt6_text {
    margin-top: 15px; }
  #content7 .title_block.title_cnt7 {
    text-align: left;
    color: #76c7d3;
    margin-top: 20px; }
  #content7 .cnt7_content {
    padding-top: 10px; }
  #content7 .title7 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold; }
  #content7 {
    padding-bottom: 220px;
    max-height: inherit; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: 25px; }
  #content8 {
    margin-top: -220px;
    background-size: 600%;
    padding-bottom: 230px;
    .right_block {
      display: none; } }
  #content8 .form_block {
    margin: -110px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 24px; }
  .form_block .form_subtitle {
    line-height: 20px;
    font-size: 16px;
    width: 90%;
    margin: 0 auto; }
  .form_block form .input-group #name_cnt8, .form_block form .input-group #phone_cnt8 {
    width: 100%;
    margin-bottom: 10px; }
  .form_block form {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; }
  .check_group {
    margin-left: 0; }
  .check_group p a {
    width: 80%; }
  .form_block form .input-group button {
    font-size: 12px; }
  .form_block form .input-group p.btn_txt {
    font-size: 14px; }
  #content9 .block_right .txt_block.border_block {
    display: none; }
  #content9 .block_left hr.red {
    margin-top: 0; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 {
    max-height: inherit; }
  #content9 .block_right img {
    float: none;
    margin-right: -15px;
    width: 100%;
    margin-top: 20px; }
  #content9 .block_left .title_block.title_cnt9 {
    color: #76c7d3;
    margin-top: 0;
    margin-left: 0; }
  #content10 {
    margin-top: -180px;
    background-image: none; }
  #content10 .block_left .video_block {
    width: 100%;
    margin-top: 20px;
    height: 170px;
    margin-left: 0; }
  #content10 .block_left {
    padding-bottom: 100px; }
  #content10 .block_right hr.red {
    margin-top: 0; }
  #content10 .block_right .cnt10_text {
    margin-top: 15px; }
  #content10 .block_right {
    padding-left: 5px;
    padding-right: 0; }
  #content11 .title_block.title_cnt11 {
    margin-top: 25px; }
  #content11 .cnt11_content {
    margin-top: 15px; }
  .cn11_img {
    height: auto; }
  #content11 .block_right {
    padding: 0; }
  #content11 .block_left {
    padding-right: 0;
    padding-bottom: 0; }
  #content11 {
    background-position: 0% 0;
    max-height: inherit;
    padding-bottom: 80px; }
  #content12 .form_block {
    margin: 25px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content12 .form_block .form_subtitle {
    width: 100%; }
  #content12 .form_block .form_btm a.btn_style2 {
    margin: 0 auto;
    width: 80%;
    display: block;
    margin-top: 35px;
    padding: 5px 0;
    height: inherit;
    margin-bottom: 18px;
    font-size: 14px; }
  #content12 .right_block {
    display: none; }
  #content12 {
    max-height: inherit;
    background-size: 530%;
    padding-bottom: 50px; }
  #content13 .title_block.title_cnt13 {
    width: 100%; }
  #content13 {
    background-image: none;
    max-height: inherit;
    background-color: #ebf3f5; }
  #content13 .cnt13_blocks {
    padding-bottom: 10px; }
  #content14 {
    max-height: inherit;
    padding-top: 15px;
    background-image: none; }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  #content14 .cnt14_content p.text {
    margin-top: 10px; }
  #content14 .cnt14_content .cnt14_block {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  #content15 {
    max-height: 915px;
    padding-bottom: 190px;
    .right_block {
      display: none; } }
  #content15 .form_block form .input-group #name_cnt15, #content15 .form_block form .input-group #phone_cnt15 {
    width: 100%;
    margin-bottom: 10px; }
  #content15 .form_block {
    margin: 30px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content16 {
    max-height: inherit;
    background-color: #ebf3f5;
    background-image: none;
    padding-bottom: 20px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 10px;
    min-height: inherit;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0; }
  #content16 .cnt16_content .cnt16_block .descr {
    padding: 0; }
  #content17 {
    background-image: none; }
  #content17 .title_block.title_cnt17 {
    padding-left: 0;
    margin-top: 20px; }
  .owl-nav {
    top: 25%; }
  #content17 #answers {
    margin-bottom: 0; }
  #content18 .form_block {
    margin: 0 auto;
    float: none;
    margin-top: 25px;
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 25px;
    width: 100%; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 0; }
  #content18 {
    -webkit-background-size: 800%;
    background-size: 800%; }
  #content18 .form_block form input {
    width: 100%; }
  #content19 .block_left .question a.quest.btn_style2 {
    font-size: 14px; }
  #content19 .block_left ul.map_list {
    margin-top: 150px; }
  #content19 .block_left {
    padding-left: 0;
    width: 100%;
    padding-right: 0; }
  #content19 .block_left ul.map_list li.active {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 12px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 19px;
    text-align: left;
    width: 100%; }
  #content19 .block_left ul.map_list li .map_phone {
    font-family: "Rubik Medium";
    color: #000;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px; }
  #content19 .block_left ul.map_list li .map_btn a.mapbtn.btn_style {
    font-size: 13px; }
  #content19 .block_left ul.map_list li {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: 0;
    text-align: right;
    position: absolute;
    top: -710px; }
  section#content19 {
    position: relative; }
  #content19 .block_right #map.map_block {
    height: 380px; }
  #content19 .block_right {
    padding-right: 0;
    padding-left: 0; }
  #content19 .block_right .subtitle.subtitle_cnt19 {
    margin-top: 0; }
  footer {
    padding-top: 25px;
    padding-bottom: 300px;
    position: relative; }
  footer .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 0;
    float: left;
    line-height: 20px;
    padding-top: 15px;
    text-align: center;
    width: 100%; }
  footer .f_inst {
    float: left;
    width: 100%; }
  footer .f_inst a {
    width: 100%; }
  .f_orange_logo.col-md-3 {
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding-left: 25px;
    left: 0;
    display: block; }
  footer .f_orange_logo a {
    color: #5a5a5a;
    float: right;
    width: 100%; }
  footer .f_orange_logo a p {
    float: left;
    display: block;
    width: 55%; }
  footer .f_callback {
    float: right;
    margin-top: 10px;
    width: 100%;
    a {
      float: left;
      width: 100%; } }
  footer {
    background-image: none;
    max-height: 275px;
    background-color: #e5f4f7;
    margin-top: 10px; } }
@media (width: 375px) {
  #design_modal .modal_right {
    padding: 50px 15px;
    padding-top: 80px; }
  .modal form .input-group p.btn_txt {
    font-size: 13px !important;
    width: 100% !important; }
  .check_group p a {
    width: 85%;
    font-size: 14px; }
  .check_group {
    width: 110% !important; }
  .right_block, .cn11_img, .cnt11_img, .img7 {
    img {
      width: 100%; } }
  img.cnt11_img {
    width: 100%; }

  #video .main_block .redb_block .subtitle_video p {
    font-size: 16px; }
  .owl-next {
    margin-right: 0; }
  .owl-prev {
    margin-left: 0; }
  header .h_metro {
    padding-left: 0; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 100%; }
  #content19 .block_left ul.map_list {
    list-style-type: none;
    margin-top: 75px;
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
    z-index: 9; }
  #content6 .txt_block.border_block {
    left: 0; }
  #content19 .block_left .question a.quest.btn_style2 {
    width: 100%; }
  #content5 .block_left .cnt5_text {
    padding-left: 0;
    float: left;
    clear: left;
    width: 100%; }
  #video #bg_block {
    display: none; }

  header {
    min-height: 320px; }
  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 40%;
      margin: 0 auto; } }
  header .h_text {
    font-size: 14px;
    padding-left: 0;
    float: left;
    line-height: 16px;
    padding-top: 12px;
    text-align: center;
    width: 100%; }
  header .h_metro {
    padding-left: 0;
    width: 55%;
    margin: 0 auto;
    float: none; }
  header .h_time {
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    display: block; }
  header .h_callback {
    float: left;
    width: 100%; }
  header .h_callback a.number {
    text-decoration: none;
    color: #000;
    font-family: Rubik Medium;
    font-size: 28px;
    line-height: 20px;
    cursor: default;
    margin-right: 0;
    padding-top: 15px;
    display: block;
    float: none;
    width: 100%;
    text-align: center; }
  header .h_callback a.callbtn {
    float: left;
    margin-top: 10px;
    width: 100%; }
  #video .main_block {
    padding: 0; }
  #video .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 100%;
    position: relative;
    z-index: 9; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 28px;
    line-height: 35px;
    padding-top: 30px; }
  .subtitle p {
    font-size: 20px;
    line-height: 22px; }
  #video .main_block .redb_block .cnt1_subblock {
    margin-top: 30px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write img.block1 {
    margin-right: 20px; }
  #video .main_block .redb_block .cnt1_write {
    width: 75%;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 10px;
    margin-bottom: 50px;
    padding-right: 25px; }
  #video .main_block .redb_block .cnt1_write img {
    margin-top: 0;
    display: inline-block; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 0;
    margin-right: 0; }
  #content1 {
    margin-top: -90px;
    padding-top: 225px; }
  #content1 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .title_block {
    font-size: 28px;
    line-height: 35px; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    margin-top: 15px;
    height: 175px; }
  #content1 .block_right .subtitle.subtitle_cnt1 {
    width: 100%;
    padding-left: 10px;
    margin-top: 15px; }
  #content1 .block_right hr.red {
    margin-top: 10px;
    margin-bottom: 10px; }
  #content1 .cnt1_text {
    padding-left: 10px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px; }
  #content2 .block_left hr.red {
    margin-top: 0px;
    float: left; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
    padding-right: 45px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px;
    background-position: 42% 0; }
  #content3 {
    padding-top: 260px;
    background-position: 45% 0;
    padding-bottom: 715px; }
  #content3 .block_left .subtitle.subtitle_cnt3 p {
    text-align: left;
    color: #188e9e; }
  #content3 .block_left hr.red {
    float: left;
    margin-top: 15px; }
  #content3 .block_left {
    padding-right: 0;
    padding-left: 0; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: left;
    margin-top: 25px;
    height: 218px;
    clear: right; }
  #content3 .block_right {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 0px;
    float: left;
    background-color: rgba(255,255,255,.9);
    width: 100%; }
  #content3 .cnt3_text {
    padding-top: 10px; }
  #content4 {
    padding-top: 185px; }
  #content4 .block_left {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  #content4 .block_left .title_block.title_cnt4 {
    font-size: 25px; }
  #content4 .block_left {
    position: relative; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 15px;
    margin-top: 29px;
    position: absolute;
    width: 100%; }
  #content4 .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: 11px;
    padding-top: 1px;
    padding-right: 0; }
  #content4 .cnt4_text {
    margin-top: 10px; }
  #content4 .block_left .img_block {
    display: none; }
  #content5 {
    padding-top: 210px; }
  #content5 .block_left hr.red {
    margin-top: 10px;
    float: left; }
  #content4 .block_left .txt_block.border_block {
    display: none; }
  #content5 .block_left .txt_block.border_block {
    display: none; }
  #content4 {
    max-height: 675px; }
  #content5 {
    background-size: 665%;
    background-position: 30% 0%;
    padding-top: 320px;
    padding-bottom: 20px; }
  #content6 {
    background-image: none; }
  #content6 .block_right {
    margin-top: 35px;
    padding-left: 0;
    padding-bottom: 10px; }
  #content6 .txt_block.border_block {
    display: none; }
  #content6 .block_right hr.red {
    margin-top: 10px; }
  #content6 .block_right .cnt6_text {
    margin-top: 15px; }
  #content7 .title_block.title_cnt7 {
    text-align: left;
    color: #76c7d3;
    margin-top: 20px; }
  #content7 .cnt7_content {
    padding-top: 10px; }
  #content7 .title7 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold; }
  #content7 {
    padding-bottom: 220px;
    max-height: inherit; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: 25px; }
  #content8 {
    margin-top: -220px;
    background-size: 600%;
    padding-bottom: 230px;
    .right_block {
      display: none; } }
  #content8 .form_block {
    margin: -110px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 24px;
    width: 80%;
    margin: 0 auto; }
  .form_block .form_subtitle {
    line-height: 20px;
    font-size: 16px;
    width: 90%;
    margin: 0 auto; }
  .form_block form .input-group #name_cnt8, .form_block form .input-group #phone_cnt8 {
    width: 100%;
    margin-bottom: 10px; }
  .form_block form {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; }
  .check_group {
    margin-left: 0; }
  .check_group p a {
    width: 85% !important; }
  .form_block form .input-group button {
    font-size: 12px; }
  .form_block form .input-group p.btn_txt {
    font-size: 16px !important; }
  #content9 .block_right .txt_block.border_block {
    display: none; }
  #content9 .block_left hr.red {
    margin-top: 0; }
  #implant_modal form .input-group textarea {
    font-size: 14px; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 {
    max-height: inherit; }
  #content9 .block_right img {
    float: none;
    margin-right: -15px;
    width: 100%;
    margin-top: 20px; }
  #content9 .block_left .title_block.title_cnt9 {
    color: #76c7d3;
    margin-top: 30px;
    margin-left: 0; }
  #content10 {
    margin-top: -180px;
    background-image: none; }
  #content10 .block_left .video_block {
    width: 100%;
    margin-top: 20px;
    height: 170px;
    margin-left: 0; }
  #content10 .block_left {
    padding-bottom: 100px; }
  #content10 .block_right hr.red {
    margin-top: 0; }
  #content10 .block_right .cnt10_text {
    margin-top: 15px; }
  #content10 .block_right {
    padding-left: 5px;
    padding-right: 0; }
  #content11 .title_block.title_cnt11 {
    margin-top: 25px; }
  #content11 .cnt11_content {
    margin-top: 15px; }
  .cn11_img {
    height: 230px; }
  #content11 .block_right {
    padding: 0; }
  #content11 .block_left {
    padding-right: 0;
    padding-bottom: 0; }
  #content11 {
    background-position: 0% 0;
    max-height: inherit;
    padding-bottom: 80px; }
  #content12 .form_block {
    margin: 25px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content12 .form_block .form_subtitle {
    width: 80%; }
  #content12 .form_block .form_btm a.btn_style2 {
    margin: 0 auto;
    width: 95%;
    display: block;
    margin-top: 35px;
    padding: 15px 0;
    height: inherit;
    margin-bottom: 18px;
    font-size: 14px; }
  #content12 .right_block {
    display: none; }
  #content12 {
    max-height: inherit;
    background-size: 530%;
    padding-bottom: 50px; }
  #content13 .title_block.title_cnt13 {
    width: 100%; }
  #content13 {
    background-image: none;
    max-height: inherit;
    background-color: #ebf3f5; }
  #content13 .cnt13_blocks {
    padding-bottom: 10px; }
  #content14 {
    max-height: inherit;
    padding-top: 15px;
    background-image: none; }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  #content14 .cnt14_content p.text {
    margin-top: 10px; }
  #content14 .cnt14_content .cnt14_block {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  #content15 {
    max-height: 915px;
    padding-bottom: 190px;
    .right_block {
      display: none; } }
  #content15 .form_block {
    margin: 30px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content15 .form_block form .input-group #name_cnt15, #content15 .form_block form .input-group #phone_cnt15 {
    width: 100%;
    margin-bottom: 10px; }
  #content16 {
    max-height: inherit;
    background-color: #ebf3f5;
    background-image: none;
    padding-bottom: 20px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 10px;
    min-height: inherit;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0; }
  #content16 .cnt16_content .cnt16_block .descr {
    padding: 0; }
  #content17 {
    background-image: none; }
  #content17 .title_block.title_cnt17 {
    padding-left: 0;
    margin-top: 20px; }
  .owl-nav {
    top: 25%; }
  #content17 #answers {
    margin-bottom: 0; }
  #content18 .form_block {
    margin: 0 auto;
    float: none;
    margin-top: 25px;
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 25px;
    width: 100%; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 0; }
  #content18 {
    -webkit-background-size: 800%;
    background-size: 800%; }
  #content18 .form_block form input {
    width: 100%; }
  #content19 .block_left .question a.quest.btn_style2 {
    font-size: 14px; }
  #content19 .block_left ul.map_list {
    margin-top: 150px; }
  #content19 .block_left {
    padding-left: 0;
    width: 100%;
    padding-right: 0; }
  #content19 .block_left ul.map_list li.active {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 12px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 19px;
    text-align: left;
    width: 100%; }
  #content19 .block_left ul.map_list li .map_phone {
    font-family: "Rubik Medium";
    color: #000;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px; }
  #content19 .block_left ul.map_list li .map_btn a.mapbtn.btn_style {
    font-size: 13px; }
  #content19 .block_left ul.map_list li {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: 0;
    text-align: right;
    position: absolute;
    width: 100%;
    top: -675px; }
  section#content19 {
    position: relative; }
  #content19 .block_right #map.map_block {
    height: 380px; }
  #content19 .block_right {
    padding-right: 0;
    padding-left: 0; }
  #content19 .block_right .subtitle.subtitle_cnt19 {
    margin-top: 0; }
  footer {
    padding-top: 25px;
    padding-bottom: 300px;
    position: relative; }
  footer .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 0;
    float: left;
    line-height: 20px;
    padding-top: 15px;
    text-align: center;
    width: 100%; }
  footer .f_inst {
    float: left;
    width: 100%; }
  footer .f_inst a {
    width: 100%; }
  .f_orange_logo.col-md-3 {
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding-left: 25px;
    left: 0;
    display: block; }
  footer .f_orange_logo a {
    color: #5a5a5a;
    float: right;
    width: 100%; }
  footer .f_orange_logo a p {
    float: left;
    display: block;
    width: 40%; }
  footer .f_callback {
    float: right;
    margin-top: 10px;
    width: 100%;
    a {
      float: left;
      width: 100%; } }
  footer {
    background-image: none;
    max-height: 275px;
    background-color: #e5f4f7;
    margin-top: 10px; } }

@media (min-width: 320px) and (max-width: 450px) {
  #question_modal form {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px; }
  #question_modal form .input-group #name_q1, #question_modal form .input-group #phone_q1 {
    width: 100%;
    margin-bottom: 10px; }
  #doctor_modal .modal_right {
    padding: 35px 30px;
    padding-bottom: 40px; }
  #doctor_modal form .input-group #name_d1, #doctor_modal form .input-group #phone_d1 {
    width: 100%;
    margin-bottom: 10px; }
  #info_modal .info_btn a.btn_left, #info_modal .info_btn a.btn_right {
    float: none;
    margin: 0 auto;
    width: 100%;
    display: block;
    margin-bottom: 10px; }
  #implant_modal form .input-group #name_imp1, #implant_modal form .input-group #phone_imp1 {
    width: 100%;
    margin-bottom: 10px; }
  #free_modal form .input-group #name_f1, #free_modal form .input-group #phone_f1 {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px; }
  #free_modal form .input-group textarea {
    font-size: 14px; }
  .modal form .input-group p.btn_txt {
    font-size: 14px !important;
    width: 100% !important; }
  .modal .title {
    font-size: 25px;
    line-height: 33px; }
  .modal .subtitle {
    line-height: 25px;
    font-size: 18px; }
  #design_modal form .input-group #name_ds1, #design_modal form .input-group #phone_ds1 {
    width: 100%;
    margin-bottom: 10px; }
  #back_call_modal .modal_right {
    padding: 30px 20px; }
  #back_call_modal form .input-group #name_bcm1, #back_call_modal form .input-group #phone_bcm1 {
    width: 100%;
    margin-bottom: 10px; }
  .modal .check_group p a {
    width: 75%; }
  #back_call_modal .subtitle {
    line-height: 30px; }
  .modal-dialog {
    margin: 0; }
  .right_block, .cn11_img, .cnt11_img, .img7 {
    img {
      width: 100%; } }
  img.cnt11_img {
    width: 100%; }

  #video .main_block .redb_block .subtitle_video p {
    font-size: 16px; }
  .owl-next {
    margin-right: 0; }
  .owl-prev {
    margin-left: 0; }
  header .h_metro {
    padding-left: 0; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 100%; }
  #content19 .block_left ul.map_list {
    list-style-type: none;
    margin-top: 75px;
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
    z-index: 9; }
  #content6 .txt_block.border_block {
    left: 0; }
  #content19 .block_left .question a.quest.btn_style2 {
    width: 100%; }
  #content5 .block_left .cnt5_text {
    padding-left: 0;
    float: left;
    clear: left;
    width: 100%; }
  #video #bg_block {
    display: none; }

  header {
    min-height: 320px; }
  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 40%;
      margin: 0 auto; } }
  header .h_text {
    font-size: 14px;
    padding-left: 0;
    float: left;
    line-height: 16px;
    padding-top: 12px;
    text-align: center;
    width: 100%; }
  header .h_metro {
    padding-left: 0;
    width: 55%;
    margin: 0 auto;
    float: none; }
  header .h_time {
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    display: block; }
  header .h_callback {
    float: left;
    width: 100%; }
  header .h_callback a.number {
    text-decoration: none;
    color: #000;
    font-family: Rubik Medium;
    font-size: 28px;
    line-height: 20px;
    cursor: default;
    margin-right: 0;
    padding-top: 15px;
    display: block;
    float: none;
    width: 100%;
    text-align: center; }
  header .h_callback a.callbtn {
    float: left;
    margin-top: 10px;
    width: 100%; }
  #video .main_block {
    padding: 0; }
  #video .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 100%;
    position: relative;
    z-index: 9; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 28px;
    line-height: 35px;
    padding-top: 30px; }
  .subtitle p {
    font-size: 20px;
    line-height: 22px; }
  #video .main_block .redb_block .cnt1_subblock {
    margin-top: 30px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write img.block1 {
    margin-right: 20px; }
  #video .main_block .redb_block .cnt1_write {
    width: 75%;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 10px;
    margin-bottom: 50px;
    padding-right: 25px; }
  #video .main_block .redb_block .cnt1_write img {
    margin-top: 0;
    display: inline-block; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 0;
    margin-right: 0; }
  #content1 {
    margin-top: -90px;
    padding-top: 225px; }
  #content1 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .title_block {
    font-size: 28px;
    line-height: 35px; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    margin-top: 15px;
    height: 175px; }
  #content1 .block_right .subtitle.subtitle_cnt1 {
    width: 100%;
    padding-left: 10px;
    margin-top: 15px; }
  #content1 .block_right hr.red {
    margin-top: 10px;
    margin-bottom: 10px; }
  #content1 .cnt1_text {
    padding-left: 10px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px; }
  #content2 .block_left hr.red {
    margin-top: 0px;
    float: left; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
    padding-right: 75px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px;
    background-position: 41% 0; }
  #content3 {
    padding-top: 260px;
    background-position: 45% 0;
    padding-bottom: 715px; }
  #content3 .block_left .subtitle.subtitle_cnt3 p {
    text-align: left;
    color: #188e9e; }
  #content3 .block_left hr.red {
    float: left;
    margin-top: 15px; }
  #content3 .block_left {
    padding-right: 0;
    padding-left: 0; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: left;
    margin-top: 25px;
    height: 218px;
    clear: right; }
  #content3 .block_right {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 0px;
    float: left;
    background-color: rgba(255,255,255,.9);
    width: 100%; }
  #content3 .cnt3_text {
    padding-top: 10px; }
  #content4 {
    padding-top: 185px; }
  #content4 .block_left {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  #content4 .block_left .title_block.title_cnt4 {
    font-size: 25px; }
  #content4 .block_left {
    position: relative; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 15px;
    margin-top: 29px;
    position: absolute;
    width: 100%; }
  #content4 .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: 11px;
    padding-top: 1px;
    padding-right: 0; }
  #content4 .cnt4_text {
    margin-top: 10px; }
  #content4 .block_left .img_block {
    display: none; }
  #content5 {
    padding-top: 210px; }
  #content5 .block_left hr.red {
    margin-top: 10px;
    float: left; }
  #content4 .block_left .txt_block.border_block {
    display: none; }
  #content5 .block_left .txt_block.border_block {
    display: none; }
  #content4 {
    max-height: 675px; }
  #content5 {
    background-size: 665%;
    background-position: 30% 0%;
    padding-top: 340px;
    padding-bottom: 20px; }
  #content6 {
    background-image: none; }
  #content6 .block_right {
    margin-top: 35px;
    padding-left: 0;
    padding-bottom: 10px; }
  #content6 .txt_block.border_block {
    display: none; }
  #content6 .block_right hr.red {
    margin-top: 10px; }
  #content6 .block_right .cnt6_text {
    margin-top: 15px; }
  #content7 .title_block.title_cnt7 {
    text-align: left;
    color: #76c7d3;
    margin-top: 20px; }
  #content7 .cnt7_content {
    padding-top: 10px;
    padding-bottom: 205px; }
  #content7 .title7 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold; }
  #content7 {
    padding-bottom: 220px;
    max-height: inherit; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: 25px; }
  #content8 {
    margin-top: -220px;
    background-size: 600%;
    padding-bottom: 230px;
    .right_block {
      display: none; } }
  #content8 .form_block {
    margin: -110px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 24px;
    width: 80%;
    margin: 0 auto; }
  .form_block .form_subtitle {
    line-height: 20px;
    font-size: 18px;
    width: 90%;
    margin: 0 auto; }
  .form_block form .input-group #name_cnt8, .form_block form .input-group #phone_cnt8 {
    width: 100%;
    margin-bottom: 10px; }
  .form_block form {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; }
  .check_group {
    margin-left: 0; }
  .check_group p a {
    width: 88%; }
  .form_block form .input-group button {
    font-size: 12px; }
  .form_block form .input-group p.btn_txt {
    font-size: 13px; }
  #content9 .block_right .txt_block.border_block {
    display: none; }
  #content9 .block_left hr.red {
    margin-top: 0; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 {
    max-height: inherit; }
  #content9 .block_right img {
    float: none;
    margin-right: -15px;
    width: 100%;
    margin-top: 20px; }
  #content9 .block_left .title_block.title_cnt9 {
    color: #76c7d3;
    margin-top: 30px;
    margin-left: 0; }
  #content10 {
    margin-top: -180px;
    background-image: none; }
  #content10 .block_left .video_block {
    width: 100%;
    margin-top: 20px;
    height: 170px;
    margin-left: 0; }
  #content10 .block_left {
    padding-bottom: 100px; }
  #content10 .block_right hr.red {
    margin-top: 0; }
  #content10 .block_right .cnt10_text {
    margin-top: 15px; }
  #content10 .block_right {
    padding-left: 5px;
    padding-right: 0; }
  #content11 .title_block.title_cnt11 {
    margin-top: 25px; }
  #content11 .cnt11_content {
    margin-top: 15px; }
  .cn11_img {
    height: 230px; }
  #content11 .block_right {
    padding: 0; }
  #content11 .block_left {
    padding-right: 0;
    padding-bottom: 0; }
  #content11 {
    background-position: 0% 0;
    max-height: inherit;
    padding-bottom: 80px; }
  #content12 .form_block {
    margin: 25px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content12 .form_block .form_subtitle {
    width: 80%; }
  #content12 .form_block .form_btm a.btn_style2 {
    margin: 0 auto;
    width: 95%;
    display: block;
    margin-top: 35px;
    padding: 15px 0;
    height: inherit;
    margin-bottom: 18px;
    font-size: 14px; }
  #content12 .right_block {
    display: none; }
  #content12 {
    max-height: inherit;
    background-size: 530%;
    padding-bottom: 50px; }
  #content13 .title_block.title_cnt13 {
    width: 100%; }
  #content13 {
    background-image: none;
    max-height: inherit;
    background-color: #ebf3f5; }
  #content13 .cnt13_blocks {
    padding-bottom: 10px; }
  #content14 {
    max-height: inherit;
    padding-top: 15px;
    background-image: none; }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  #content14 .cnt14_content p.text {
    margin-top: 10px; }
  #content14 .cnt14_content .cnt14_block {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  #content15 {
    max-height: 915px;
    padding-bottom: 190px;
    .right_block {
      display: none; } }
  #content15 .form_block {
    margin: 30px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content15 .form_block form .input-group #name_cnt15, #content15 .form_block form .input-group #phone_cnt15 {
    width: 100%;
    margin-bottom: 10px; }
  #content16 {
    max-height: inherit;
    background-color: #ebf3f5;
    background-image: none;
    padding-bottom: 20px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 10px;
    min-height: inherit;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0; }
  #content16 .cnt16_content .cnt16_block .descr {
    padding: 0; }
  #content17 {
    background-image: none; }
  #content17 .title_block.title_cnt17 {
    padding-left: 0;
    margin-top: 20px; }
  .owl-nav {
    top: 25%; }
  #content17 #answers {
    margin-bottom: 0; }
  #content18 .form_block {
    margin: 0 auto;
    float: none;
    margin-top: 25px;
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 25px;
    width: 100%; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 0; }
  #content18 {
    -webkit-background-size: 800%;
    background-size: 800%; }
  #content18 .form_block form input {
    width: 100%; }
  #content19 .block_left .question a.quest.btn_style2 {
    font-size: 14px; }
  #content19 .block_left ul.map_list {
    margin-top: 150px; }
  #content19 .block_left {
    padding-left: 0;
    width: 100%;
    padding-right: 0; }
  #content19 .block_left ul.map_list li.active {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 12px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 19px;
    text-align: left;
    width: 100%; }
  #content19 .block_left ul.map_list li .map_phone {
    font-family: "Rubik Medium";
    color: #000;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px; }
  #content19 .block_left ul.map_list li .map_btn a.mapbtn.btn_style {
    font-size: 13px; }
  #content19 .block_left ul.map_list li {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: 0;
    text-align: right;
    position: absolute;
    width: 100%;
    top: -675px; }
  section#content19 {
    position: relative; }
  #content19 .block_right #map.map_block {
    height: 380px; }
  #content19 .block_right {
    padding-right: 0;
    padding-left: 0; }
  #content19 .block_right .subtitle.subtitle_cnt19 {
    margin-top: 0; }
  footer {
    padding-top: 25px;
    padding-bottom: 300px;
    position: relative; }
  footer .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 0;
    float: left;
    line-height: 20px;
    padding-top: 15px;
    text-align: center;
    width: 100%; }
  footer .f_inst {
    float: left;
    width: 100%; }
  footer .f_inst a {
    width: 100%; }
  .f_orange_logo.col-md-3 {
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding-left: 25px;
    left: 0;
    display: block; }
  footer .f_orange_logo a {
    color: #5a5a5a;
    float: right;
    width: 100%; }
  footer .f_orange_logo a p {
    float: left;
    display: block;
    width: 40%; }
  footer .f_callback {
    float: right;
    margin-top: 10px;
    width: 100%;
    a {
      float: left;
      width: 100%; } }
  footer {
    background-image: none;
    max-height: 275px;
    background-color: #e5f4f7;
    margin-top: 10px; } }



@media (min-width: 560px) and (max-width: 740px) {
  #content15 .form_block {
    padding-left: 15px; }
  .check_group p a {
    width: 85%; }
  .cn11_img {
    max-width: 448px;
    height: 264px;
    max-height: 200px; }
  #back_call_modal .modal_left {
    display: none; }
  header {
    height: 100px;
    .logo {
      width: 100px;
      img {
        width: 100%; } }
    .h_text {
      font-size: 12px;
      float: left;
      clear: left;
      padding-left: 0;
      width: 30%;
      line-height: 15px;
      padding-top: 12px; } }
  header .h_metro {
    float: left;
    padding-left: 45px;
    font-size: 12px;
    line-height: 20px;
    width: 21%;
    margin-top: -40px; }
  header .h_time {
    float: left;
    font-size: 12px;
    line-height: 16px;
    width: 28%;
    position: absolute;
    left: 280px;
    top: 58px; }
  header .h_callback {
    float: right;
    width: 40%;
    margin-top: -40px; }
  header .h_callback a.number {
    width: 100%;
    text-align: right;
    font-size: 22px;
    padding-right: 20px;
    padding-bottom: 5px; }
  #video .main_block .redb_block .video_title {
    font-size: 55px; }
  .subtitle p {
    font-size: 18px;
    line-height: 26px;
    font-family: Gilroy Light; }
  .cnt1_write img {
    width: 16%; }
  .cnt1_write img.block3 {
    width: 25%;
    padding-top: 5px; }
  #video .main_block .redb_block .cnt1_write {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write p {
    font-size: 16px; }
  #content1 {
    padding-top: 370px; }
  #content1 .block_left {
    padding-right: 0; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 50%;
    margin-top: 40px;
    height: 255px;
    float: left; }
  #content1 .block_right {
    margin-left: 0;
    padding-top: 34px;
    padding-right: 0;
    color: #fff;
    float: left;
    width: 100%; }
  #content2 {
    padding-top: 240px;
    background-position: 58% 0px; }
  #content2 .block_left {
    padding-left: 0;
    width: 100%; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 81%;
    padding-left: 0;
    padding-top: 28px;
    padding-right: 90px; }
  #content3 {
    padding-top: 185px;
    background-position: 58% 0%; }
  #content3 .block_left {
    padding-right: 0;
    width: 50%;
    float: left; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: right;
    margin-top: 68px;
    height: 285px;
    clear: right; }
  #content3 .block_right {
    margin-top: 155px;
    padding-left: 22px;
    padding-right: 0;
    float: left;
    width: 50%; }
  #content13 .title_block.title_cnt13 {
    padding-top: 25px; }
  #content4 {
    padding-top: 245px; }
  .title_block {
    font-family: Gilroy ExtraBold;
    line-height: 50px;
    font-size: 40px;
    text-transform: uppercase; }
  #content1 .block_left .title_block.title_cnt1 {
    color: #76c6d2;
    text-align: right;
    width: 80%;
    float: right;
    margin-right: 30px; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 0px;
    margin-top: 201px;
    width: 32%;
    img {
      width: 100%; } }
  #content4 .block_right {
    color: #fff;
    padding-left: 40px;
    margin-left: -5px;
    padding-top: 1px;
    padding-right: 0;
    float: left;
    width: 65%; }
  #content4 .cnt4_text {
    margin-top: 20px;
    font-size: 14px;
    padding-right: 20px; }
  #content4 .block_left .txt_block.border_block {
    position: absolute;
    bottom: 0;
    width: 220px;
    height: 170px;
    padding: 15px 20px;
    top: 225px;
    right: 0;
    left: initial; }
  #content5 .block_left .cnt5_text {
    float: left;
    width: 45%;
    padding-left: 25px;
    margin-top: 30px;
    color: #5a5a5a; }
  #content5 .block_left .cnt5_text {
    float: left;
    width: 45%;
    padding-left: 0;
    margin-top: 30px;
    color: #5a5a5a;
    padding-bottom: 120px;
    clear: left; }

  #content5 .block_left .subtitle.subtitle_cnt5 {
    width: 60%;
    text-align: left;
    color: #188e9e;
    margin-top: -135px; }
  #content5 .block_left .txt_block.border_block {
    right: 70px;
    width: 218px;
    bottom: -65px;
    height: 120px;
    padding: 20px;
    color: #000;
    background-color: rgba(255,255,255,0.7); }
  #content6 .block_right {
    margin-top: -85px;
    padding-left: 20px;
    padding-bottom: 233px;
    float: right;
    width: 70%;
    text-align: right; }
  #content6 .block_right .subtitle.subtitle_cnt6 {
    color: #188e9e;
    width: 50%;
    float: right; }
  #content6 .block_right hr.red {
    margin-top: 0;
    float: right;
    clear: right; }

  #content6 .block_right .cnt6_text {
    margin-top: 15px;
    color: #5a5a5a;
    float: right;
    width: 100%;
    padding-left: 24%; }

  #content7 .col-md-4 {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content7 .text7 p {
    margin-bottom: 8px;
    line-height: 18px;
    font-family: "Rubik Light";
    font-size: 12px; }
  #content7 .title7 p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: -40px; }
  .form_block .form_title {
    font-family: "Gilroy ExtraBold";
    font-size: 31px;
    line-height: 38px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  .form_block .form_subtitle {
    font-family: "Gilroy Light";
    line-height: 25px;
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 80%;
    margin: 0 auto; }
  .form_block form {
    width: 75%;
    margin: 0 auto;
    margin-top: 40px; }
  .check_group {
    margin-top: 0px;
    width: 100%;
    margin-left: 5px;
    float: none;
    padding-top: 10px; }
  .form_block form .input-group p.btn_txt {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 13px;
    text-align: center;
    line-height: 22px;
    margin-top: 15px; }
  #content8 .right_block img {
    width: 120%; }
  #content8 .right_block .r_text {
    color: #fff;
    font-family: "Rubik Light";
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    padding-left: 60px;
    margin-top: 20px;
    width: 120%; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 60%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 .block_right {
    margin-top: 0;
    padding-bottom: 185px;
    width: 40%;
    float: left;
    img {
      width: 100%; } }
  #content9 .block_right .txt_block.border_block {
    left: -20px;
    width: 220px;
    top: 60px;
    height: auto;
    color: #000;
    padding: 30px;
    background-color: rgba(255,255,255,0.7); }
  #content10 .block_left {
    padding-bottom: 318px;
    width: 50%;
    float: left; }
  #content10 .block_right {
    padding-left: 20px;
    float: left;
    width: 50%; }
  #content10 .block_right .cnt10_text {
    margin-top: 20px; }
  #content10 .block_left .video_block {
    background-color: #aaa;
    width: 94%;
    margin-top: 20px;
    height: 225px;
    margin-left: 5px; }
  #content11 .block_left {
    padding-left: 0;
    padding-bottom: 240px;
    width: 50%;
    float: left;
    img {
      width: 100%; } }
  #content11 .block_right.col-md-6 {
    float: left;
    width: 50%;
    img {
      width: 100%; } }
  #content11 .cnt11_content {
    margin-top: 25px; }
  #content12 .right_block p.txt {
    color: #fff;
    padding-left: 80px;
    font-size: 12px;
    text-align: left;
    line-height: 22px;
    width: 115%; }
  #content12 .right_block img {
    width: 120%; }
  #content12 .form_block .form_subtitle {
    width: 80%;
    font-size: 17px;
    padding-left: 15px; }
  #content12 .form_block .form_btm a.btn_style2 {
    width: 80%; }
  #content13 .cnt13_block.col-md-4 {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content13 .cnt13_blocks .name_block a {
    font-family: "Gilroy Light";
    color: #188e9e;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    transition: .5s; }
  #content13 .cnt13_blocks .subtitle_block p {
    font-size: 14px; }
  #content14 {
    margin-top: -40px; }
  #content14 .cnt14_content .cnt14_block {
    width: 33.3%;
    float: left;
    img {
      width: 100%; } }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 15px; }
  #content14 .cnt14_content p.text {
    margin-top: 20px;
    font-size: 14px; }
  #content15 .right_block img {
    width: 120%; }
  #content15 .right_block p.txt {
    color: #fff;
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    display: block;
    padding-left: 70px;
    margin-top: 28px;
    width: 115%; }
  #content16 .subtitle.subtitle_cnt16 {
    margin-top: -40px; }
  #content16 .cnt16_block.col-md-4 {
    width: 33.3%;
    float: left; }
  #content16 .cnt16_content .cnt16_block img {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 30px; }
  #content16 .cnt16_content .cnt16_block .descr {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    font-size: 13px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 50px;
    min-height: 350px;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 134px; }
  #content17 #answers {
    width: 90%;
    float: none;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 220px; }
  #content18 .form_block {
    margin-top: -75px;
    padding-top: 0; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 50px; }
  #content17 {
    -webkit-background-size: 227%;
    background-size: 227%; }
  #content19 .block_left {
    padding-left: 0;
    width: 50%;
    float: left; }
  #content19 .block_right {
    padding-right: 0;
    width: 50%;
    float: left; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: -70px;
    text-align: right;
    width: 120%;
    margin-left: -20%; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 16px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 25px;
    text-align: left;
    width: 100%; }
  footer {
    padding-top: 20px;
    height: 135px; }
  footer .f_inst {
    float: left;
    clear: left;
    width: 30%;
    padding-left: 0;
    padding-top: 10px;
    a {
      width: 100%;
      p {
        margin: 0; } } }
  footer .f_orange_logo.col-md-3 {
    padding-top: 10px;
    float: left;
    width: 35%;
    a {
      width: 100%; } }
  footer .f_orange_logo a p {
    float: left;
    margin: 0;
    display: block;
    width: 45%;
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px; }
  #content19 .block_right #map.map_block {
    height: 510px; }
  footer .f_callback {
    float: right;
    margin-top: -40px; }
  .form_block form .input-group input {
    padding-left: 15px;
    font-size: 14px; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  .owl-next {
    margin-right: -30px; }
  .owl-prev {
    margin-left: -30px; }
  #content4 .block_left {
    padding-right: 0; } }
@media (max-width: 760px) {
  #video {
    background-image: url(../images/main_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    #bg_block {
      display: none; } }
  .modal-body {
    .modal_left {
      display: none; } } }
@media (width: 568px) {
  header {
    height: 115px; }
  header .h_text {
    width: 22%; }
  header .h_metro {
    padding-left: 0;
    margin-top: -30px; }
  header .h_time {
    float: left;
    font-size: 12px;
    line-height: 16px;
    width: 36%;
    position: absolute;
    left: 135px;
    top: 70px; }
  header .h_callback a.number {
    margin-right: 0;
    font-size: 20px; }
  header .h_callback {
    width: 35%; }
  header .h_callback a.callbtn {
    font-size: 13px; }
  #video .main_block .redb_block .video_title {
    font-size: 38px;
    line-height: 42px; }
  #video .main_block .redb_block {
    min-width: 100%; }
  #video .main_block .redb_block .cnt1_write {
    margin-top: 55px; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 25px;
    margin-right: 25px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 100%; }
  #content19 .block_left ul.map_list {
    list-style-type: none;
    margin-top: 75px;
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
    z-index: 9; }
  #content6 .txt_block.border_block {
    left: 0; }
  #content19 .block_left .question a.quest.btn_style2 {
    width: 100%; }
  #content5 .block_left .cnt5_text {
    padding-left: 0;
    float: left;
    clear: left;
    width: 100%;
    padding-bottom: 0; }
  #video #bg_block {
    display: none; }
  #video .main_block {
    padding: 0; }
  #video .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 100%;
    position: relative;
    z-index: 9; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 28px;
    line-height: 35px;
    padding-top: 30px; }
  .subtitle p {
    font-size: 20px;
    line-height: 22px; }
  #video .main_block .redb_block .cnt1_subblock {
    margin-top: 30px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 95%; }
  #video .main_block .redb_block .cnt1_write {
    width: 75%;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 10px;
    margin-bottom: 50px;
    padding-right: 25px; }
  #video .main_block .redb_block .cnt1_write img {
    margin-top: 0;
    display: inline-block; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 0;
    margin-right: 0; }
  #content1 {
    margin-top: -90px;
    padding-top: 225px; }
  #content1 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .title_block {
    font-size: 28px;
    line-height: 35px; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    margin-top: 15px;
    height: 310px; }
  #content1 .block_right .subtitle.subtitle_cnt1 {
    width: 100%;
    padding-left: 10px;
    margin-top: 15px; }
  #content1 .block_right hr.red {
    margin-top: 10px;
    margin-bottom: 10px; }
  #content1 .cnt1_text {
    padding-left: 10px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px; }
  #content2 .block_left hr.red {
    margin-top: 0px;
    float: left; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
    padding-right: 175px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px;
    background-position: 42% 0; }
  #content3 {
    padding-top: 240px;
    background-position: 41% 0;
    background-size: 260%; }
  #content3 .block_left .subtitle.subtitle_cnt3 p {
    text-align: left;
    color: #188e9e; }
  #content3 .block_left hr.red {
    float: left;
    margin-top: 15px; }
  #content3 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: left;
    margin-top: 25px;
    height: 315px;
    clear: right; }
  #content3 .block_right {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 0px;
    float: left;
    background-color: rgba(255,255,255,.9);
    width: 100%; }
  #content3 .cnt3_text {
    padding-top: 10px; }
  #content4 {
    padding-top: 210px; }
  #content4 .block_left {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  #content4 .block_left .title_block.title_cnt4 {
    font-size: 26px; }
  #content4 .block_left {
    position: relative; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 15px;
    margin-top: 29px;
    position: absolute;
    width: 100%; }
  #content4 .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: 11px;
    padding-top: 1px;
    padding-right: 0;
    width: 100%; }
  #content4 .cnt4_text {
    margin-top: 10px; }
  #content4 .block_left .img_block {
    display: none; }
  #content5 {
    padding-top: 210px; }
  #content5 .block_left hr.red {
    margin-top: 10px;
    float: left; }
  #content4 .block_left .txt_block.border_block {
    display: none; }
  #content5 .block_left .txt_block.border_block {
    display: none; }
  #content4 {
    max-height: 790px; }
  #content5 {
    background-size: 665%;
    background-position: 40% 20%;
    padding-top: 285px;
    padding-bottom: 20px; }
  #content6 {
    background-image: none; }
  #content6 .block_right {
    margin-top: 35px;
    padding-left: 0;
    padding-bottom: 10px;
    width: 100%; }
  #content6 .txt_block.border_block {
    display: none; }
  #content6 .block_right hr.red {
    margin-top: 10px; }
  #content6 .block_right .cnt6_text {
    margin-top: 15px; }
  #content7 .title_block.title_cnt7 {
    text-align: left;
    color: #76c7d3;
    margin-top: 20px; }
  #content7 .cnt7_content {
    padding-top: 10px;
    padding-bottom: 30px; }
  #content7 .title7 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold; }
  #content7 {
    padding-bottom: 220px;
    max-height: inherit; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: 25px; }
  #content8 {
    margin-top: 0px;
    background-size: 600%;
    padding-bottom: 140px;
    .right_block {
      display: none; } }
  #content8 .form_block {
    margin: -110px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 24px; }
  .form_block .form_subtitle {
    line-height: 20px;
    font-size: 16px;
    width: 90%;
    margin: 0 auto; }
  .form_block form .input-group #name_cnt8, .form_block form .input-group #phone_cnt8 {
    width: 100%;
    margin-bottom: 10px; }
  .form_block form {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; }
  .check_group {
    margin-left: 0; }
  .check_group p a {
    width: 80%; }
  .form_block form .input-group button {
    font-size: 12px; }
  .form_block form .input-group p.btn_txt {
    font-size: 14px; }
  #content9 .block_right .txt_block.border_block {
    display: none; }
  #content9 .block_left hr.red {
    margin-top: 0; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 {
    max-height: inherit; }
  #content9 .block_right img {
    float: none;
    margin-right: -15px;
    width: 100%;
    margin-top: 20px; }
  #content9 .block_left .title_block.title_cnt9 {
    color: #76c7d3;
    margin-top: 25px;
    margin-left: 0; }
  #content10 {
    margin-top: -180px;
    background-image: none; }
  #content10 .block_left .video_block {
    width: 100%;
    margin-top: 20px;
    height: 275px;
    margin-left: 0; }
  #content11 .block_left img {
    width: 95%; }
  #content10 .block_left {
    width: 100%;
    margin-top: -120px;
    float: left;
    padding-bottom: 120px; }
  #content10 .block_right hr.red {
    margin-top: 0; }
  #content10 .block_right .cnt10_text {
    margin-top: 15px; }
  #content10 .block_right {
    padding-left: 5px;
    padding-right: 0;
    width: 100%; }
  #content11 .title_block.title_cnt11 {
    margin-top: 25px; }
  #content11 .cnt11_content {
    margin-top: 15px; }
  .cn11_img {
    height: auto; }
  #content11 .block_right {
    padding: 0; }
  #content11 .block_left {
    padding-right: 0;
    padding-bottom: 0; }
  #content11 {
    background-position: 0% 0;
    max-height: inherit;
    padding-bottom: 80px; }
  #content12 .form_block {
    margin: 25px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content12 .form_block .form_subtitle {
    width: 100%; }
  #content12 .form_block .form_btm a.btn_style2 {
    margin: 0 auto;
    width: 80%;
    display: block;
    margin-top: 35px;
    padding: 5px 0;
    height: inherit;
    margin-bottom: 18px;
    font-size: 14px; }
  #content12 .right_block {
    display: none; }
  #content12 {
    max-height: inherit;
    background-size: 530%;
    padding-bottom: 50px; }
  #content13 .title_block.title_cnt13 {
    width: 100%; }
  #content13 {
    background-image: none;
    max-height: inherit;
    background-color: #ebf3f5; }
  #content13 .cnt13_blocks {
    padding-bottom: 10px; }
  #content14 {
    max-height: inherit;
    padding-top: 70px;
    background-image: none; }
  #content14 .cnt14_content .cnt14_block {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
    img {
      width: 80%;
      margin: 0 auto;
      display: block; } }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  #content14 .cnt14_content p.text {
    margin-top: 10px; }
  #content14 .cnt14_content .cnt14_block {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  #content15 {
    max-height: 915px;
    padding-bottom: 190px;
    .right_block {
      display: none; } }
  #content15 .form_block form .input-group #name_cnt15, #content15 .form_block form .input-group #phone_cnt15 {
    width: 100%;
    margin-bottom: 10px; }
  #content15 .form_block {
    margin: 30px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content16 {
    max-height: inherit;
    background-color: #ebf3f5;
    background-image: none;
    padding-bottom: 20px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 10px;
    min-height: inherit;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0; }
  #content16 .cnt16_content .cnt16_block .descr {
    padding: 0; }
  #content17 {
    background-image: none; }
  #content17 .title_block.title_cnt17 {
    padding-left: 0;
    margin-top: 20px; }
  .owl-nav {
    top: 25%; }
  #content17 #answers {
    margin-bottom: 0; }
  #content18 .form_block {
    margin: 0 auto;
    float: none;
    margin-top: 25px;
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 25px;
    width: 100%; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 0; }
  #content18 {
    -webkit-background-size: 800%;
    background-size: 800%; }
  #content18 .form_block form input {
    width: 100%; }
  #content19 .block_left .question a.quest.btn_style2 {
    font-size: 14px; }
  #content19 .block_left ul.map_list {
    margin-top: 150px; }
  #content19 .block_left {
    padding-left: 0;
    width: 100%;
    padding-right: 0; }
  #content19 .block_left ul.map_list li.active {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 12px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 19px;
    text-align: left;
    width: 100%; }
  #content19 .block_left ul.map_list li .map_phone {
    font-family: "Rubik Medium";
    color: #000;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px; }
  #content19 .block_left ul.map_list li .map_btn a.mapbtn.btn_style {
    font-size: 13px; }
  #content19 .block_left ul.map_list li {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: 0;
    text-align: right;
    position: absolute;
    top: -660px; }
  section#content19 {
    position: relative; }
  #content19 .block_right #map.map_block {
    height: 380px; }
  #content19 .block_right {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  #content19 .block_right .subtitle.subtitle_cnt19 {
    margin-top: 0; }
  footer {
    padding-top: 25px;
    padding-bottom: 175px;
    position: relative; }
  footer .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 25px;
    float: left;
    line-height: 20px;
    padding-top: 10px;
    text-align: left;
    width: 60%; }
  footer .f_inst {
    float: left;
    width: 50%; }
  footer .f_inst a {
    width: 100%; }
  .f_orange_logo.col-md-3 {
    float: left;
    position: absolute;
    bottom: 0px;
    padding-left: 25px;
    left: 0;
    width: 65% !important;
    display: block; }
  footer .f_orange_logo a {
    color: #5a5a5a;
    float: right;
    width: 100%; }
  footer .f_orange_logo a p {
    float: left;
    display: block;
    width: 30%; }
  footer .f_callback {
    float: right;
    margin-top: 15px;
    width: 50%;
    a {
      float: left;
      width: 100%; } }
  footer {
    background-image: none;
    max-height: 275px;
    background-color: #e5f4f7;
    margin-top: 10px; } }
.modal-body .col-md-12 {
  padding: 0; }
#map_modal_msp {
  width: 100%;
  max-width: 100%;
  max-height: 600px;
  height: 600px; }



@media (min-width: 1024px) {
  header {
    position: fixed;
    z-index: 10;
    width: 100%; }
  #video .main_block {
    .redb_block {
      margin-top: 150px; } } }

@media (width: 667px) {
  .thx_left {
    display: none; }
  header {
    height: 115px; }
  header .h_text {
    width: 22%; }
  header .h_metro {
    padding-left: 0;
    margin-top: -30px; }
  header .h_time {
    float: left;
    font-size: 12px;
    line-height: 16px;
    width: 36%;
    position: absolute;
    left: 135px;
    top: 70px; }
  header .h_callback a.number {
    margin-right: 0;
    font-size: 20px; }
  header .h_callback {
    width: 35%; }
  header .h_callback a.callbtn {
    font-size: 13px; }
  #video .main_block .redb_block .video_title {
    font-size: 38px;
    line-height: 42px; }
  #video .main_block .redb_block {
    min-width: 100%; }
  #video .main_block .redb_block .cnt1_write {
    margin-top: 55px; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 25px;
    margin-right: 25px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 100%; }
  #content19 .block_left ul.map_list {
    list-style-type: none;
    margin-top: 75px;
    padding-left: 0;
    margin-left: -30px;
    margin-right: 0;
    position: relative;
    z-index: 9; }
  #content6 .txt_block.border_block {
    left: 0; }
  #content19 .block_left .question a.quest.btn_style2 {
    width: 100%; }
  #content5 .block_left .cnt5_text {
    padding-left: 0;
    float: left;
    clear: left;
    width: 100%;
    padding-bottom: 0; }
  #video #bg_block {
    display: none; }
  #video .main_block {
    padding: 0; }
  #video .main_block .redb_block {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    max-width: 100%;
    position: relative;
    z-index: 9; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 28px;
    line-height: 35px;
    padding-top: 30px; }
  .subtitle p {
    font-size: 20px;
    line-height: 22px; }
  #video .main_block .redb_block .cnt1_subblock {
    margin-top: 30px; }
  #video .main_block .redb_block .cnt1_btnblock a.btn_style2 {
    width: 55%; }
  #video .main_block .redb_block .cnt1_write {
    width: 75%;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 10px;
    margin-bottom: 50px;
    padding-right: 25px; }
  #video .main_block .redb_block .cnt1_write img {
    margin-top: 0;
    display: inline-block; }
  #video .main_block .redb_block .cnt1_write img.block2 {
    margin-left: 10px;
    margin-right: 10px; }
  #content1 {
    margin-top: -90px;
    padding-top: 225px; }
  #content1 .block_left .title_block.title_cnt1 {
    width: 90%; }
  #content1 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  .title_block {
    font-size: 28px;
    line-height: 35px; }
  #content1 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    margin-top: 15px;
    height: 310px; }
  #content1 .block_right .subtitle.subtitle_cnt1 {
    width: 100%;
    padding-left: 10px;
    margin-top: 15px; }
  #content1 .block_right hr.red {
    margin-top: 10px;
    margin-bottom: 10px; }
  #content1 .cnt1_text {
    padding-left: 10px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px; }
  #content2 .block_left hr.red {
    margin-top: 0px;
    float: left; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 85%;
    padding-left: 0;
    padding-top: 15px;
    padding-right: 175px; }
  #content2 {
    padding-top: 210px;
    padding-bottom: 33px;
    background-position: 42% 0; }
  #content3 {
    padding-top: 240px;
    background-position: 42% 0;
    background-size: 222%; }
  #content3 .block_left .subtitle.subtitle_cnt3 p {
    text-align: left;
    color: #188e9e; }
  #content3 .block_left hr.red {
    float: left;
    margin-top: 15px; }
  #content3 .block_left {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  #content3 .block_left .video_block {
    background-color: #aaa;
    width: 100%;
    float: left;
    margin-top: 25px;
    height: 315px;
    clear: right; }
  #content3 .block_right {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 0px;
    float: left;
    background-color: rgba(255,255,255,.9);
    width: 100%; }
  #content3 .cnt3_text {
    padding-top: 10px; }
  #content4 {
    padding-top: 210px; }
  #content4 .block_left {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  #content4 .block_left .title_block.title_cnt4 {
    font-size: 26px;
    width: 75%;
    float: right; }
  #content4 .block_left {
    position: relative; }
  #content4 .block_left .img_block {
    float: right;
    margin-right: 15px;
    margin-top: 29px;
    position: absolute;
    width: 100%; }
  #content4 .block_right {
    color: #fff;
    padding-left: 0;
    margin-left: 11px;
    padding-top: 1px;
    padding-right: 0;
    width: 100%; }
  #content4 .cnt4_text {
    margin-top: 10px; }
  #content4 .block_left .img_block {
    display: none; }
  #content5 {
    padding-top: 210px; }
  #content5 .block_left hr.red {
    margin-top: 10px;
    float: left; }
  #content4 .block_left .txt_block.border_block {
    display: none; }
  #content5 .block_left .txt_block.border_block {
    display: none; }
  #content4 {
    max-height: 790px; }
  #content5 {
    background-size: 665%;
    background-position: 35% 20%;
    padding-top: 285px;
    padding-bottom: 20px; }
  #content7 .title7 p {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px; }
  #content6 {
    background-image: none; }
  #content6 .block_right {
    margin-top: 35px;
    padding-left: 0;
    padding-bottom: 10px;
    width: 100%; }
  #content6 .txt_block.border_block {
    display: none; }
  #content6 .block_right hr.red {
    margin-top: 10px; }
  #content6 .block_right .cnt6_text {
    margin-top: 15px; }
  #content7 .title_block.title_cnt7 {
    text-align: left;
    color: #76c7d3;
    margin-top: 20px; }
  #content7 .cnt7_content {
    padding-top: 10px;
    padding-bottom: 30px; }
  #content7 .title7 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold; }
  #content7 {
    padding-bottom: 220px;
    max-height: inherit; }
  #content7 .block_top:last-child, #content7 .block_bottom:last-child {
    padding-right: 10px;
    padding-left: 15px; }
  #content7 .block_bottom.col-md-4.last_block {
    margin-top: -36px; }

  #content8 {
    margin-top: 0px;
    background-size: 600%;
    padding-bottom: 140px;
    .right_block {
      display: none; } }
  #content8 .form_block {
    margin: -110px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  .form_block .form_title, #video .main_block .redb_block .video_title {
    font-size: 24px; }
  .form_block .form_subtitle {
    line-height: 20px;
    font-size: 16px;
    width: 90%;
    margin: 0 auto; }
  .form_block form .input-group #name_cnt8, .form_block form .input-group #phone_cnt8 {
    width: 100%;
    margin-bottom: 10px; }
  .form_block form {
    width: 65%;
    margin: 0 auto;
    margin-top: 20px; }
  .check_group {
    margin-left: 0; }
  .check_group p a {
    width: 80%;
    font-size: 11px; }
  .form_block form .input-group button {
    font-size: 12px; }
  .form_block form .input-group p.btn_txt {
    font-size: 14px; }
  #content9 .block_right .txt_block.border_block {
    display: none; }
  #content9 .block_left hr.red {
    margin-top: 0; }
  #content9 .block_left .cnt9_text {
    float: left;
    width: 100%;
    padding-left: 0;
    padding-top: 13px;
    color: #5a5a5a;
    clear: left; }
  #content9 {
    max-height: inherit; }
  #content9 .block_right img {
    float: none;
    margin-right: -15px;
    width: 100%;
    margin-top: 20px; }
  #content9 .block_left .title_block.title_cnt9 {
    color: #76c7d3;
    margin-top: 25px;
    margin-left: 0; }
  #content10 {
    margin-top: -180px;
    background-image: none; }
  #content10 .block_left .video_block {
    width: 100%;
    margin-top: 20px;
    height: 275px;
    margin-left: 0; }
  #content11 .block_left img {
    width: 95%; }
  #content10 .block_left {
    width: 100%;
    margin-top: -120px;
    float: left;
    padding-bottom: 120px; }
  #content10 .block_right hr.red {
    margin-top: 0; }
  #content10 .block_right .cnt10_text {
    margin-top: 15px; }
  #content10 .block_right {
    padding-left: 5px;
    padding-right: 0;
    width: 100%; }
  #content11 .title_block.title_cnt11 {
    margin-top: 25px; }
  #content11 .cnt11_content {
    margin-top: 15px; }
  .cn11_img {
    height: 178px;
    img {
      width: 100%; } }
  .subtitle.subtitle_cnt11 {
    font-size: 15px; }
  #content11 .block_right {
    padding: 0; }
  #content11 .block_left {
    padding-right: 0;
    padding-bottom: 0; }
  #content11 {
    background-position: 0% 0;
    max-height: inherit;
    padding-bottom: 80px; }
  #content12 .form_block {
    margin: 25px auto;
    width: 70%;
    padding-top: 0;
    padding-bottom: 10px;
    float: none; }

  #content12 .form_block .form_subtitle {
    width: 100%; }
  #content12 .form_block .form_btm a.btn_style2 {
    margin: 0 auto;
    width: 80%;
    display: block;
    margin-top: 35px;
    padding: 5px 0;
    height: inherit;
    margin-bottom: 18px;
    font-size: 14px; }
  #content12 .right_block {
    display: none; }
  #content12 {
    max-height: inherit;
    background-size: 530%;
    padding-bottom: 50px; }
  #content13 .title_block.title_cnt13 {
    width: 100%; }
  #content13 {
    background-image: none;
    max-height: inherit;
    background-color: #ebf3f5; }
  #content13 .cnt13_blocks {
    padding-bottom: 10px; }
  #content14 {
    max-height: inherit;
    padding-top: 70px;
    background-image: none; }
  #content14 .cnt14_content .cnt14_block {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
    img {
      width: 35%;
      margin: 0;
      display: block; } }
  #content14 .cnt14_content .cnt14_block:first-child {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
  #content14 .cnt14_content p.text {
    margin-top: 10px; }
  #content14 .cnt14_content .cnt14_block {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  #content15 {
    max-height: 915px;
    padding-bottom: 190px;
    .right_block {
      display: none; } }
  #content15 .form_block form .input-group #name_cnt15, #content15 .form_block form .input-group #phone_cnt15 {
    width: 100%;
    margin-bottom: 10px; }
  #content15 .form_block {
    margin: 30px 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 10px; }
  #content16 {
    max-height: inherit;
    background-color: #ebf3f5;
    background-image: none;
    padding-bottom: 20px; }
  #content16 .cnt16_content .cnt16_block {
    border: 5px solid transparent;
    padding-top: 10px;
    min-height: inherit;
    transition: .5s;
    cursor: pointer;
    margin-bottom: 0; }
  #content16 .cnt16_content .cnt16_block .descr {
    padding: 0; }
  #content17 {
    background-image: none; }
  #content17 .title_block.title_cnt17 {
    padding-left: 0;
    margin-top: 20px; }
  .owl-nav {
    top: 25%; }
  #content17 #answers {
    margin-bottom: 0; }
  #content18 .form_block {
    margin: 0 auto;
    float: none;
    margin-top: 25px;
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 25px;
    width: 100%; }
  #content18 .form_block .form_title, #content18 .form_block .form_subtitle, #content18 .form_block form {
    top: 0; }
  #content18 {
    -webkit-background-size: 800%;
    background-size: 800%; }
  #content18 .form_block form input {
    width: 100%; }
  #content19 .block_left .question a.quest.btn_style2 {
    font-size: 14px;
    width: 50%; }
  #content19 .block_left ul.map_list {
    margin-top: 150px; }
  #content19 .block_left {
    padding-left: 0;
    width: 100%;
    padding-right: 0; }
  #content19 .block_left ul.map_list li.active {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_left ul.map_list li .map_adress {
    font-family: "Rubik Light";
    font-size: 12px;
    margin-top: 2px;
    color: #5a5a5a;
    line-height: 19px;
    text-align: left;
    width: 100%; }
  #content19 .block_left ul.map_list li .map_phone {
    font-family: "Rubik Medium";
    color: #000;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px; }
  #content19 .block_left ul.map_list li .map_btn a.mapbtn.btn_style {
    font-size: 13px; }
  #content19 .block_left ul.map_list li {
    padding: 30px;
    padding-right: 10px; }
  #content19 .block_right .title_block.title_cnt19 {
    color: #76c7d3;
    margin-top: 0;
    text-align: left;
    position: absolute;
    top: -660px;
    margin-left: 0;
    width: 100%; }
  #content19 .block_right hr.red {
    float: left;
    margin-top: 4px;
    clear: left; }
  section#content19 {
    position: relative; }
  #content19 .block_right #map.map_block {
    height: 380px; }
  #content19 .block_right {
    padding-right: 0;
    padding-left: 0;
    width: 100%; }
  #content19 .block_right .subtitle.subtitle_cnt19 {
    margin-top: 0; }
  footer {
    padding-top: 25px;
    padding-bottom: 175px;
    position: relative; }
  footer .f_text {
    font-family: Gilroy ExtraBold;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
    padding-left: 25px;
    float: right;
    line-height: 20px;
    padding-top: 10px;
    text-align: left;
    width: 50%; }
  footer .f_inst {
    float: left;
    width: 50%; }
  footer .f_inst a {
    width: 100%; }
  .f_orange_logo.col-md-3 {
    float: left;
    position: absolute;
    bottom: 0px;
    padding-left: 25px;
    left: 0;
    width: 65% !important;
    display: block; }
  footer .f_orange_logo a {
    color: #5a5a5a;
    float: right;
    width: 100%; }
  footer .f_orange_logo a p {
    float: left;
    display: block;
    width: 30%; }
  footer .f_callback {
    float: right;
    margin-top: 15px;
    width: 50%;
    a {
      float: left;
      width: 100%; } }
  footer {
    background-image: none;
    max-height: 275px;
    background-color: #e5f4f7;
    margin-top: 10px; } }
.modal-body .col-md-12 {
  padding: 0; }
#map_modal_msp {
  width: 100%;
  max-width: 100%;
  max-height: 600px;
  height: 600px; }
@media (width: 736px) {
  #video .main_block .redb_block {
    margin-top: 20px; }
  #content7 .title7 p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 20px; }
  #content2 .block_left .cnt2_text {
    float: left;
    width: 65%;
    padding-left: 0;
    padding-top: 28px;
    padding-right: 125px;
    clear: left; }
  #content1 {
    max-height: 965px; }
  #content2 {
    padding-top: 240px;
    background-position: 45% 0px; }
  #content3 {
    padding-top: 230px;
    background-position: 45% 0%;
    background-size: 238%; }
  #content1 .block_right {
    margin-left: 0;
    padding-top: 34px;
    padding-right: 0;
    color: #fff;
    float: left;
    width: 50%; } }

